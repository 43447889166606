import React, { useState, useEffect } from "react";
import { artstudio } from "../../content/artstudio";

const ArtStudioGallery = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  // Automatically slide to the next image every 5 seconds
  useEffect(() => {
    if (!isHovered) {
      const interval = setInterval(() => {
        handleNext();
      }, 5000);
      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [activeSlide, isHovered]);

  const handleNext = () => {
    setActiveSlide((prevActiveSlide) => (prevActiveSlide + 1) % artstudio.length);
  };

  const handlePrev = () => {
    setActiveSlide((prevActiveSlide) =>
      (prevActiveSlide - 1 + artstudio.length) % artstudio.length
    );
  };

  const handleThumbnailClick = (index: number) => {
    setActiveSlide(index);
  };

  return (
    <div 
      className="ArtStudioGallery" 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Main Image */}
      <div className="main-slide">
        <button onClick={handlePrev} className="prevButton">
          <span className="material-symbols-outlined">arrow_back_ios</span>
        </button>

        {artstudio.map((image, index) => (
          <div
            key={index}
            className={`slide ${activeSlide === index ? "active" : ""}`}
          >
            <img
              src={image.src}
              alt={image.alt}
              loading="lazy"
              title={image.title}
              className="main-image"
            />
          </div>
        ))}

        <button onClick={handleNext} className="nextButton">
          <span className="material-symbols-outlined">arrow_forward_ios</span>
        </button>
      </div>

      {/* Thumbnails */}
      <div className="thumbnails">
        {artstudio.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            className={`thumbnail ${activeSlide === index ? "active" : ""}`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>

      {/* Navigation Dots */}
      <div className="dots">
        {artstudio.map((_, index) => (
          <span
            key={index}
            className={`dot ${activeSlide === index ? "active" : ""}`}
            onClick={() => handleThumbnailClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ArtStudioGallery;