import Heartstream from "../media/artpage/Abstracts/Heart.jpg";
import FlyingNature from "../media/artpage/Abstracts/Flying Nature by Luna Smith.jpg";
import Happiness from "../media/artpage/Abstracts/HappinessByLunaSmith.jpg";
import LifeArchitect from "../media/artpage/Abstracts/LifeArchitectByLunaSmtih.jpg";
import DreamsOfSorrow from "../media/artpage/Abstracts/DreamsOfSorrowByLunaSmith.jpg";
import ComplexVoid from "../media/artpage/Abstracts/CompexVoidByLunaSmith.jpg";
import Perspective from "../media/artpage/Abstracts/PerspectiveByLunaSmith_main.jpg";

import HeartstreamBg from "../media/artpage/Abstracts/Heartstream_background1.jpg";
import FlyingNatureBg from "../media/artpage/Abstracts/Flying Nature by Luna Smith - background.jpg";
import HappinessBg from "../media/artpage/Abstracts/HappinessByLunaSmith-background.jpg";
import LifeArchitectBg from "../media/artpage/Abstracts/LifeArchitectByLunaSmtih_background.jpg";
import DreamsOfSorrowBg from "../media/artpage/Abstracts/DreamsOfSorrowByLunaSmith-background.jpg";
import ComplexVoidBg from "../media/artpage/Abstracts/CompexVoidByLunaSmith-background.jpg";
import PerspectiveBg from "../media/artpage/Abstracts/PerspectiveByLunaSmith_background.jpg";

export const abstractsCollection = [
    {    
        src: Heartstream,
        srcBg: HeartstreamBg,
        alt: "Heartstream by Luna Smith - oil painting",
        about: "Abstracts",
        keyWords: "heart, love, stream, abstract, Valentine",
        title: "Heartstream",
        description:
            "",
        button: "Sold",
        price: "£515",
        link: "",
        linkPayPal: "",
        dimension: "30 X 40 X 1.5 cm",
    },
    {    
        src: FlyingNature,
        srcBg: FlyingNatureBg,
        alt: "Flying Nature by Luna Smith - oil painting",
        about: "Abstracts",
        keyWords: "portrait, hat, bird, abstract, woman, girl",
        title: "Flying Nature",
        description:
            "",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/7sIcO5b4LfzR5oYg0P",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=XZBC8UJDMUUPC",
        dimension: "30 X 40 X 1.5 cm",
    },
    {    
        src: Perspective,
        srcBg: PerspectiveBg,
        alt: "Perspective by Luna Smith - oil painting",
        about: "Abstracts",
        keyWords: "portrait, head, abstract, man, brain, human",
        title: "Perspective",
        description:
            "",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/cN2g0ha0Hafx7x62ai",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=ZYJ4WJYQ8CAGL",
        dimension: "30 X 40 X 1.5 cm",
    },
    {    
        src: ComplexVoid,
        srcBg: ComplexVoidBg,
        alt: "Complex Void by Luna Smith - oil painting",
        about: "Abstracts",
        keyWords: "portrait, woman, abstract, woman nature, tree, womenhood, girl",
        title: "Complex Void",
        description:
            "",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/4gw15n5Krafx5oY3en",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=V2MSE4CEAFJHY",
        dimension: "30 X 40 X 1.5 cm",
    },
    {    
        src: LifeArchitect,
        srcBg: LifeArchitectBg,
        alt: "Life Architect by Luna Smith - oil painting",
        about: "Abstracts",
        keyWords: "pportrait, woman, abstract, woman nature, tree, womenhood, girl, blue",
        title: "Life Architect",
        description:
            "",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/4gw15n6OvgDV9Fe4is",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JQ447LRRXPKNE",
        dimension: "30 X 40 X 1.5 cm",
    },
    {    
        src: Happiness,
        srcBg: HappinessBg,
        alt: "Happiness by Luna Smith - oil painting",
        about: "Abstracts",
        keyWords: "portrait, woman, abstract, woman nature, tree, womenhood, girl, red",
        title: "Happiness",
        description:
            "",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/fZebK18WD73l3gQbKV",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=KVLMAT3MBNUR8",
        dimension: "30 X 40 X 1.5 cm",
    },
    {    
        src: DreamsOfSorrow,
        srcBg: DreamsOfSorrowBg,
        alt: "Dreams Of Sorrow by Luna Smith - oil painting",
        about: "Abstracts",
        keyWords: "portrait, woman, abstract, woman nature, tree, womenhood, girl, blue, sadness",
        title: "Dreams Of Sorrow",
        description:
            "",
        button: "Card",
        price: "£515",
        link: "https://buy.stripe.com/00geWd0q7bjB2cM6qC",
        linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PA257RHEFJ3BQ",
        dimension: "30 X 40 X 1.5 cm",
    },
]