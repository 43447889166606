import React from "react";
import { nottCollectionBuy } from "../../content/artpageNott";
import ArtGallery from "./ArtGallery";

interface PaintingProps {
  src: string;
  srcBg: string;
  alt: string;
  about: string;
  title: string;
  description: string;
  button: string;
  price: string;
  link: string;
  linkPayPal: string;
  dimension: string;
  keyWords: string;
}

const Nott = () => {
  return (
    <>
      <h2>Nott</h2>
      <p>
        Nótt is an art collection created by Scottish painter, Luna Smith, that
        takes the viewer on a mesmerizing journey through the endless loop of
        wonders. This collection of fifteen oil paintings explores the
        connection between the past, present, and future through symbolic
        imagery of the personalization of the Night from Norse mythology, and
        through the lens of Spirits, Goddesses, and Stones. The limited symphony
        of colors, with hues reminiscent of the night sky, creates a sense of
        depth and mystery in our Universe. Each painting is a window into a
        different world, where various shapes and souls come to life, inviting
        the viewer to discover their hidden meanings.
      </p>
      {nottCollectionBuy.map((nottPainting: PaintingProps, index: number) => {
        return (
          <div key={index}>
            <ArtGallery
              about={nottPainting.about}
              alt={nottPainting.alt}
              description={nottPainting.description}
              price={nottPainting.price}
              title={nottPainting.title}
              src={nottPainting.src}
              srcBg={nottPainting.srcBg}
              button={nottPainting.button}
              link={nottPainting.link}
              linkPayPal={nottPainting.linkPayPal}
              dimension={nottPainting.dimension}
              keyWords={nottPainting.keyWords}
            />
          </div>
        );
      })}
    </>
  );
};

export default Nott;
