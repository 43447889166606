import React from "react";
import { useParams } from "react-router-dom";
import Artwork from "../components/Art/Artwork";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import { animalsCollection, hatchingEggsCollection } from "../content/artpageAnimals"
import { landscapesCollection } from "../content/artpageLandscape"
import { shadowsCollection } from "../content/artpageShadows"
import { soundOfLifeCollection } from "../content/artpageSoundOfLife"
import { portraitsCollection } from "../content/artpagePortrait"
import { nottCollectionBuy } from "../content/artpageNott"
import { abstractsCollection } from "../content/artpageAbstracts";
import { stillLifeCollection } from "../content/artpageStillLife";
import Artist from "../media/artpage/Nott/artist.jpg";

const ArtworkPage = ({mode}) => {
  const { title } = useParams();

  const allCollections = [
   landscapesCollection,
   animalsCollection,
   hatchingEggsCollection,
   shadowsCollection,
   soundOfLifeCollection,
   portraitsCollection,
   nottCollectionBuy,
   abstractsCollection,
   stillLifeCollection
  ];

  const artworks = allCollections.reduce((result, collection) => {
    return [...result, ...Object.values(collection)];
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={`A stunning contemporary artwork created by Luna Smith, available at LuArt Gallery.`}  />
        <meta name="keywords" content="art, contemporary, Scottish art, Scottish artist, oil painting, painting, fine art, buy art, invest in art, home decor, artist, Luna Smith, LuArt Gallery" />
        <link rel="canonical" href={`https://luartgallery.com/art}`} />
         {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" />
        <meta
          name="twitter:title"
          content={`Fine art by Luna Smith`}
        />
        <meta
          name="twitter:description"
          content={`Explore fine art, a stunning artwork by Luna Smith.`}
        />
        <meta name="twitter:image" content={Artist} />
        <meta name="twitter:creator" content="@LuArtGallery" />

        {/* Open Graph Meta Tags for Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`Fine art by Luna Smith`} />
        <meta property="og:description" content={`Discover fine art, a beautiful artwork by Luna Smith, available at LuArt Gallery.`} />
        <meta property="og:url" content={`https://luartgallery.com/art`} />
        <meta property="og:image" content={Artist} />
        <meta property="og:site_name" content="LuArt Gallery" />
      </Helmet>
      <ScrollToTop />
      <Artwork artworks={artworks} mode={mode} />
    </>
  );
};

export default ArtworkPage;