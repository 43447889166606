import React, { useRef, useState } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import emailjs from "@emailjs/browser";
import Feather from "../media/feather.png";
import FeatherL from "../media/featherL.png";

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Completed = ({mode, handleMode, setCategory}: ModeProps) => {
  const form = useRef<any>();
  const [submit, setSubmit] = useState(false);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_wnq7xgs",
          "template_jybanxb",
          form.current,
          "cdMexHjomTqiCa1rY"
        )
        .then(
          (result) => {
            console.log(result.text);
            setSubmit(true);
            form.current.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <div className="Completed">
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory} />
      <h2>Thank you</h2>
      <h3>Your transaction was completed!</h3>
      <h4>
        Within three days, we will carefully pack and ship your painting,
        providing you with a tracking number to keep you informed about the
        arrival of the artwork.
      </h4> 
      <h4><span>Kindly provide your email address to receive updates on your package.</span></h4>
      <form ref={form} onSubmit={sendEmail} className="ContactForm">
        <div className="inputContainer">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="user_name"
            placeholder="enter your name..."
            required
          />
          <img src={Feather} alt="feather" className="feather1" title="feather" loading="lazy" width="60px" height="60px"/>
        </div>
        <div className="inputContainer">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="user_email"
            placeholder="enter your email..."
            required
          />
          <img src={Feather} alt="feather" className="feather2"  title="feather" loading="lazy" width="60px" height="60px"/>
        </div>
        <div className="textContainer">
          <label htmlFor="message">Message</label>
          <textarea
            name="user_message"
            placeholder="write a note or a special request..."
          />
          <img src={Feather} alt="feather" className="feather3" title="feather" loading="lazy" width="60px" height="60px" />
          <img src={FeatherL} alt="feather" className="featherL" title="feather" loading="lazy" width="60px" height="60px"/>
        </div>
        <div className="checkbox">
          <input type="checkbox" id="checkbox" required />
          <label htmlFor="checkbox">I am not a robot</label>
        </div>
        {!submit ? (
          <p className="lackResponse"></p>
        ) : (
          <p className="response">
            <span>
              <strong>
                Thank you. We will respond to you shortly.
              </strong>
            </span>
          </p>
        )}
        <input type="submit" value="Send" className="formButton" /> 
        <p>You can call/text us at +44-7516-746847</p>
      </form>
      <Footer mode={mode}/>
    </div>
  );
};

export default Completed;
