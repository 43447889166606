import Owl from "../media/artpage/Animals/Owl-by-Luna-Smith.jpg";
import Swan from "../media/artpage/Animals/Swan-by-Luna-Smith.jpg";
import Shrimp from "../media/artpage/Animals/Shrimp-by-Luna-Smith.jpg";
import Dream from "../media/artpage/Animals/Dream-by-Luna-Smith.jpg";
import SpookyKitty from "../media/artpage/Animals/SpookyKitty_ByLunaSmith.jpg";
import Fluffy from "../media/artpage/Animals/Fluffy.jpg";
import Chichi from "../media/artpage/Animals/ChichiByLunaSmith.jpg";
import OwlBg from "../media/artpage/Animals/Owl.jpg";
import SwanBg from "../media/artpage/Animals/Swan.jpg";
import ShrimpBg from "../media/artpage/Animals/Shrimp.jpg";
import DreamBg from "../media/artpage/Animals/Dream.jpg";
import SpookyKittyBg from "../media/artpage/Animals/SpookyKittyBackground.jpg";
import FluffyBg from "../media/artpage/Animals/Fluffy-background.jpg";
import ChichiBg from "../media/artpage/Animals/ChichiByLunaSmith-background.jpg";

import BloodEggs from "../media/artpage/Animals/HatchingEggs/Blood-Eggs-by-Luna-Smith.jpg";
import FireDragon from "../media/artpage/Animals/HatchingEggs/Fire-Dragon-by-Luna-Smith.jpg";
import BabyLizard from "../media/artpage/Animals/HatchingEggs/Hatching-Baby-Lizard-by-Luna-Smith.jpg";
import BabyTurtle from "../media/artpage/Animals/HatchingEggs/Hatching-Baby-Turtle-by-Luna-Smith.jpg";
import Chick from "../media/artpage/Animals/HatchingEggs/Hatching-Chick-by-Luna-Smith.jpg";
import Dino from "../media/artpage/Animals/HatchingEggs/Hatching-Dino-by-Luna-Smith.jpg";
import Dinosaur from "../media/artpage/Animals/HatchingEggs/Hatching-Dinosaur-by-Luna-Smith.jpg";
import Dragon from "../media/artpage/Animals/HatchingEggs/Hatching-Dragon-by-Luna-Smith.jpg";
import Fluff from "../media/artpage/Animals/HatchingEggs/Hatching-Fluff-by-Luna-Smith.jpg";
import FluffyChick from "../media/artpage/Animals/HatchingEggs/Hatching-Fluffy-Chick-by-Luna-Smith.jpg";
import Rock from "../media/artpage/Animals/HatchingEggs/Hatching-Rock-by-Luna-Smith.jpg";
import Sunshine from "../media/artpage/Animals/HatchingEggs/Hatching-Sunshine-by-Luna-Smith.jpg";
import Turtle from "../media/artpage/Animals/HatchingEggs/Hatching-Turtle-by-Luna-Smith.jpg";
import Turtles from "../media/artpage/Animals/HatchingEggs/Hatching-Turtles-by-Luna-Smith.jpg";
import Watcher from "../media/artpage/Animals/HatchingEggs/Hatching-Watcher-by-Luna-Smith.jpg";
import WhiteSnake from "../media/artpage/Animals/HatchingEggs/Hatching-White-Snake-by-Luna-Smith.jpg";
import MythicEgg from "../media/artpage/Animals/HatchingEggs/Mythic-Egg-by-Luna-Smith.jpg";
import PoppyEggs from "../media/artpage/Animals/HatchingEggs/Poppy-Eggs-by-Luna-Smith.jpg";
import RainbowEgg from "../media/artpage/Animals/HatchingEggs/Rainbow-Egg-by-Luna-Smith.jpg";
import RelaxChick from "../media/artpage/Animals/HatchingEggs/Relax-the-Chick-Is-Hatching-by-Luna-Smith.jpg";
import BlueDragon from "../media/artpage/Animals/HatchingEggs/Hatching-Blue-Dragon-by-Luna-Smith.jpg";
import BloodEggsBg from "../media/artpage/Animals/HatchingEggs/BloodEggs.jpg";
import FireDragonBg from "../media/artpage/Animals/HatchingEggs/FireDragon.jpg";
import BabyLizardBg from "../media/artpage/Animals/HatchingEggs/BabyLizard.jpg";
import BabyTurtleBg from "../media/artpage/Animals/HatchingEggs/BabyTurtle.jpg";
import ChickBg from "../media/artpage/Animals/HatchingEggs/HatchingChick.jpg";
import DinoBg from "../media/artpage/Animals/HatchingEggs/HatchingDino.jpg";
import DinosaurBg from "../media/artpage/Animals/HatchingEggs/HatchingDinosaur.jpg";
import DragonBg from "../media/artpage/Animals/HatchingEggs/HatchingDragon.jpg";
import FluffBg from "../media/artpage/Animals/HatchingEggs/HatchingFluff.jpg";
import FluffyChickBg from "../media/artpage/Animals/HatchingEggs/FluffyChick.jpg";
import RockBg from "../media/artpage/Animals/HatchingEggs/HatchingRock.jpg";
import SunshineBg from "../media/artpage/Animals/HatchingEggs/HatchingSunshine.jpg";
import TurtleBg from "../media/artpage/Animals/HatchingEggs/HatchingTurtle.jpg";
import TurtlesBg from "../media/artpage/Animals/HatchingEggs/HatchingTurtles.jpg";
import WatcherBg from "../media/artpage/Animals/HatchingEggs/HatchingWatcher.jpg";
import WhiteSnakeBg from "../media/artpage/Animals/HatchingEggs/WhiteSnake.jpg";
import MythicEggBg from "../media/artpage/Animals/HatchingEggs/MythicEgg.jpg";
import PoppyEggsBg from "../media/artpage/Animals/HatchingEggs/PoppyEggs.jpg";
import RainbowEggBg from "../media/artpage/Animals/HatchingEggs/Rainbow-Egg.jpg";
import RelaxChickBg from "../media/artpage/Animals/HatchingEggs/RelaxChick.jpg";
import BlueDragonBg from "../media/artpage/Animals/HatchingEggs/HatchingBlueDragon.jpg";


export const animalsCollection = [
  {
    src: Owl,
    srcBg: OwlBg,
    alt: "Owl by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "clouds, bird, nature, animals, animal, feathers",
    title: "Owl",
    description:
      "The painting depicts a proud bird of prey perched on an old tree stump covered in moss, set against a background of brilliantly colorful clouds. The owl is the central focus of the painting, with its powerful gaze and sharp talons conveying a sense of strength and grace.  Each brushstroke is visible, giving the clouds a sense of texture and depth that adds to the overall vibrancy of the scene. The use of color in this painting is particularly effective, creating a sense of awe and wonder that perfectly complements the majesty of the owl.",
    button: "Card",
    price: "£1200",
    link: "https://buy.stripe.com/8wM3dvegXdrJcRqcNc",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=GQH6EQZS69SSN",
    dimension: "50 X 60 X 1.5 cm",
  },
  {
    src: Swan,
    srcBg: SwanBg,
    alt: "Swan by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "water, bird, nature, animals, animal, feathers, lake, landing",
    title: "Swan",
    description:
      "The painting captures the moment of a swan landing on the water with grace, power, and elegance. The swan is the focal point of the painting, with its long neck extended and wings outstretched. The wings are only just touching the water's surface, creating a ripple effect that adds a sense of movement and dynamism to the scene.  It reminds us of the importance of preserving and protecting the natural world, and the beauty that can be found in even the simplest of moments.",
    button: "Card",
    price: "£1200",
    link: "https://buy.stripe.com/6oE4hz6OvgDVg3CbJ9",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=E6UU8BZ6MJ974",
    dimension: "60 X 50 X 1.5 cm",
  },
  {
    src: Shrimp,
    srcBg: ShrimpBg,
    alt: "Shrimp by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "under the sea, water, nature, animals, animal, peackock, ocean, creature",
    title: "Shrimp",
    description:
      "The painting depicts a stunning and powerful creature - the Peacock Mantis Shrimp - in all its vibrant and colorful glory. The scene takes place underwater, with the shrimp perched on a bed of rocks. The Peacock Mantis Shrimp is an incredible creature, known for its powerful punches and stunning colors. The painting captures these qualities beautifully, with the shrimp's bright and iridescent colors shining in the light. Its powerful claws are visible, adding a sense of strength and fierceness to the scene.",
    button: "Card",
    price: "£195",
    link: "https://buy.stripe.com/dR66pHgp55Zh4kUcNe",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=AZGAZ6HGG6LE2",
    dimension: "30 X 24.5 X 1.5 cm",
  },
  {
    src: Dream,
    srcBg: DreamBg,
    alt: "Dream by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "kitty, cat, cats, fish, imagination, aquarium, water, nature, animals, animal, pet",
    title: "Dream",
    description:
      "This beautiful and enchanting painting captures the innocence and wonder of a kitten's dreams. The focal point of the painting is the kitten's head, which takes up most of the canvas. The kitten's face is exquisitely painted, with its huge, captivating eyes taking center stage. The eyes seem to be looking directly at the viewer, drawing them in with their intensity and depth. The kitten's head is surrounded by a flurry of color and movement, as fish and butterflies swim and flutter around it. The colors are bright and vivid, with shades of pink, blue, and orange creating a sense of whimsy and magic. The scene appears to be underwater, with the fish and butterflies seemingly swimming through the water, but the presence of the kitten and butterflies is a clear indication that this is a dream.",
    button: "Sold",
    price: "£550",
    link: "",
    linkPayPal: "",
    dimension: "40 X 40 X 1.5 cm",
  },
  {
    src: SpookyKitty,
    srcBg: SpookyKittyBg,
    alt: "Spooky Kitty by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "cat, cats, room, animals, animal, pet, Haloween, portait",
    title: "Spooky Kitty",
    description:
      "Spooky Kitty is a delightful and whimsical painting created by a talented Scottish fine art artist. This enchanting artwork was thoughtfully designed for Halloween, blending the spirit of the holiday with a charming touch. In the center of the composition, a cute little kitty sits serenely with its eyes closed, embodying a sense of calm and innocence. Surrounding the feline friend are little scary ghost toys, providing a playful and spooky element to the scene. The background of the painting adds a sense of mystery and intrigue to the overall composition. It appears as though the room is transitioning into a ghostly dimension, with light emanating from the walls. ",
    button: "Sold",
    price: "£495",
    link: "",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Fluffy,
    srcBg: FluffyBg,
    alt: "Fluffy by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "dog, dogs, nature, animals, animal, pet, chihuahua, portrait",
    title: "Fluffy",
    description:
      "This painting captures a close-up portrait of a small chihuahua, with expressive and soulful eyes. The use of thick, textured brushstrokes emphasizes the fur, giving it a soft and fluffy appearance. The fur is rendered in a mix of colors including shades of beige, white, and hints of pink and yellow, adding depth and vibrancy to the piece. The background is composed of muted greens and blues, allowing the subject to stand out prominently. The cute dog's gaze is directed off to the side, adding a sense of contemplation and emotion to the painting.",
    button: "Card",
    price: "£155",
    link: "https://buy.stripe.com/9AQ01j7Sz1J118I6pX",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=5FKVX7FVHVTH6",
    dimension: "18 X 24 X 1.5 cm",
  },
  {
    src: Chichi,
    srcBg: ChichiBg,
    alt: "Chichi by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "dog, dogs, nature, animals, animal, pet, chihuahua, portrait, fluffy",
    title: "Chichi",
    description:
      "This artwork captures the whimsical charm of a fluffy, white dog surrounded by floating bubbles. The dog's fur, painted with soft brushstrokes, contrasts beautifully against the warm, abstract background of oranges and yellows. The bubbles, delicate and iridescent, add a playful touch, reflecting light and color as they float around the dog. The composition creates a sense of joy and curiosity, as Chichi gazes at the bubbles with a sense of wonder, embodying the innocence and delight of a moment frozen in time.",
    button: "Card",
    price: "£595",
    link: "https://buy.stripe.com/9AQ5lD6OvfzRbNm5mt",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4BXH4WL6296LN",
    dimension: "40 X 40 X 1.5 cm",
  },
];

export const hatchingEggsCollection = [
  {
    src: BloodEggs,
    srcBg: BloodEggsBg,
    alt: "Blood Eggs by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, red",
    title: "Blood Eggs",
    description: "",
    button: "Card",
    price: "£550",
    link: "https://buy.stripe.com/aEU3dv2yf73l5oY3cG",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=B5Q7KGWRQX4QC",
    dimension: "40 X 40 X 1.5 cm",
  },
  {
    src: FireDragon,
    srcBg: FireDragonBg,
    alt: "Fire Dragon by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, fire, dragon, mythical beast, creature, fantasy, reptile, Jurassic, flying",
    title: "Fire Dragon",
    description: "",
    button: "Sold",
    price: "£155",
    link: "",
    linkPayPal: "",
    dimension: "25 X 30 X 1.5 cm",
  },
  {
    src: BabyLizard,
    srcBg: BabyLizardBg,
    alt: "Hatching Baby Lizard by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, reptile",
    title: "Hatching Baby Lizard",
    description: "",
    button: "Card",
    price: "£95",
    link: "https://buy.stripe.com/9AQg0h0q7fzRbNmeVq",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=M9WQV43K8E3XQ",
    dimension: "25 X 15 X 1.5 cm",
  },
  {
    src: BabyTurtle,
    srcBg: BabyTurtleBg,
    alt: "Hatching Baby Turtle by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, tortoise, eggs, reptile",
    title: "Hatching Baby Turtle",
    description: "",
    button: "Card",
    price: "£155",
    link: "https://buy.stripe.com/8wMcO5b4LdrJbNmdRn",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=FC94VE2H96NK6",
    dimension: "30 X 25 X 1.5 cm",
  },
  {
    src: Chick,
    srcBg: ChickBg,
    alt: "Hatching Chick by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, bird, feathers, baby, chicken, bird, birds",
    title: "Hatching Chick",
    description: "",
    button: "Card",
    price: "£495",
    link: "https://buy.stripe.com/00gg0h8WDfzRbNm9B8",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=HQ8TEP57F8B3J",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: Dino,
    srcBg: DinoBg,
    alt: "Hatching Dino by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, dinosaur, baby, eye, creature, Haloween, reptile, Jurassic",
    title: "Hatching Dino",
    description: "",
    button: "Sold",
    price: "£150",
    link: "",
    linkPayPal: "",
    dimension: "23 X 30 X 1.5 cm",
  },
  {
    src: Dinosaur,
    srcBg: DinosaurBg,
    alt: "Hatching Dinosaur by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, dino, mythical beast, creature, reptil, Jurassic",
    title: "Hatching Dinosaur",
    description: "",
    button: "Card",
    price: "£495",
    link: "https://buy.stripe.com/7sI7tL7SzdrJcRqfZy",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=HBY8ZRA9649U2",
    dimension: "40 X 30 X 1.5 cm",
  },
  {
    src: Dragon,
    srcBg: DragonBg,
    alt: "Hatching Dragon by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, dino, dinosaur, mythical beast, creature, green, Jurassic",
    title: "Hatching Dragon",
    description: "",
    button: "Card",
    price: "£900",
    link: "https://buy.stripe.com/4gw4hz5KrcnFcRq8x7",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=K7MB7VN65S4EN",
    dimension: "50 X 40 X 1.5 cm",
  },
  {
    src: Fluff,
    srcBg: FluffBg,
    alt: "Hatching Fluff by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, red, alien, creature, fluffy, pet, children",
    title: "Hatching Fluff",
    description: "",
    button: "Card",
    price: "£900",
    link: "https://buy.stripe.com/dR69BT8WD4Vd04EbJk",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=KHE4663HCJJPQ",
    dimension: "40 X 49.5 X 1.5 cm",
  },
  {

    src: FluffyChick,
    srcBg: FluffyChickBg,
    alt: "Hatching Fluffy Chick by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, bird, baby, chicken, feathers, bird, birds",
    title: "Hatching Fluffy Chick",
    description: "",
    button: "Card",
    price: "£155",
    link: "https://buy.stripe.com/00g8xP1ub1J13gQaFh",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=9AJWXJ7WQMKT8",
    dimension: "30 X 25 X 1.5 cm",
  },
  {

    src: Rock,
    srcBg: RockBg,
    alt: "Hatching Rock by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, treasure, gem, purple, abstract",
    title: "Hatching Rock",
    description: "",
    button: "Card",
    price: "£550",
    link: "https://buy.stripe.com/9AQ01ja0HbjB6t214I",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=EZS2GGY5VHQJU",
    dimension: "40 X 40 X 1.5 cm",
  },
  {

    src: Sunshine,
    srcBg: SunshineBg,
    alt: "Hatching Sunshine by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, yellow",
    title: "Hatching Sunshine",
    description: "",
    button: "Card",
    price: "£550",
    link: "https://buy.stripe.com/fZecO5gp54Vd8Ba9Bf",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RSQNTULRUWES4",
    dimension: "40 X 40 X 1.5 cm",
  },
  {

    src: Turtle,
    srcBg: TurtleBg,
    alt: "Hatching Turtle by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, tortoise, pet, reptile",
    title: "Hatching Turtle",
    description: "",
    button: "Sold",
    price: "£120",
    link: "https://buy.stripe.com/5kAdS93CjcnFdVu14K",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=5GFEQ4B6DW9QU",
    dimension: "25 X 20 X 1.5 cm",
  },
  {

    src: Turtles,
    srcBg: TurtlesBg,
    alt: "Hatching Turtles by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, tortoise, pet, reptile",
    title: "Hatching Turtles",
    description: "",
    button: "Card",
    price: "£145",
    link: "https://buy.stripe.com/28oeWdfl14Vdg3CbJp",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4YKEQUW9BV4T8",
    dimension: "30 X 19.5 X 1.5 cm",
  },
  {

    src: Watcher,
    srcBg: WatcherBg,
    alt: "Hatching Watcher by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, Haloween, green, monster, mythical beast, creature, reptile",
    title: "Hatching Watcher",
    description: "",
    button: "Card",
    price: "£550",
    link: "https://buy.stripe.com/7sI8xP3CjgDV18IeVC",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=D2MW5RPPAA7RY",
    dimension: "40 X 40 X 1.5 cm",
  },
  {

    src: WhiteSnake,
    srcBg: WhiteSnakeBg,
    alt: "Hatching White Snake by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, pet, reptile",
    title: "Hatching White Snake",
    description: "",
    button: "Card",
    price: "£155",
    link: "https://buy.stripe.com/7sI3dvegXdrJ6t2dRz",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JNQT8PYAA9AM6",
    dimension: "25 X 30 X 1.5 cm",
  },
  {

    src: MythicEgg,
    srcBg: MythicEggBg,
    alt: "Mythic Egg by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, blue",
    title: "Mythic Egg",
    description: "",
    button: "Card",
    price: "£550",
    link: "https://buy.stripe.com/aEUcO52yf5Zh6t25l4",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=C69NCBETGZH4A",
    dimension: "40 X 40 X 1.5 cm",
  },
  {

    src: PoppyEggs,
    srcBg: PoppyEggsBg,
    alt: "Poppy Eggs by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, red",
    title: "Poppy Eggs",
    description: "",
    button: "Card",
    price: "£900",
    link: "https://buy.stripe.com/00g4hz3Cj5Zh8Ba7td",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RS79RXNUXJG7C",
    dimension: "49.5 X 39.5 X 1.5 cm",
  },
  {

    src: RainbowEgg,
    srcBg: RainbowEggBg,
    alt: "Rainbow Egg by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, abstract, rainbow, colors, colourful",
    title: "Rainbow Egg",
    description: "",
    button: "Card",
    price: "£4500",
    link: "https://buy.stripe.com/00g15nc8PbjBg3CeWK",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=8ESNKJXL5G6K2",
    dimension: "101.5 X 75.5 X 1.5 cm",
  },
  {

    src: RelaxChick,
    srcBg: RelaxChickBg,
    alt: "Relax, the Chick is Hatching by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, chiken, bird, feathers, birds",
    title: "Relax, the Chick is Hatching",
    description: "",
    button: "Card",
    price: "£145",
    link: "https://buy.stripe.com/4gwcO5c8PevNdVudRD",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=P6LAHLTVM9CTA",
    dimension: "30.5 X 20 X 3.2 cm",
  },
  {

    src: BlueDragon,
    srcBg: BlueDragonBg,
    alt: "Hatching Blue Dragon by Luna Smith - oil painting",
    about: "Animals",
    keyWords: "egg, Easter, animals, animal, spring, new life, hope, mythical beast, creature, reptile, Jurassic, flying",
    title: "Hatching Blue Dragon",
    description: "",
    button: "Card",
    price: "£195",
    link: "https://buy.stripe.com/8wM4hz1ubdrJaJi14S",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=Q5KRQMCQKBS88",
    dimension: "35 X 25.3 X 3 cm",
  },
];

