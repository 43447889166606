import React from "react";
import FutureIcon1 from '../../media/upcomingExhibition/ExhibitionIconFuture.jpg';
import FutureIcon2 from '../../media/upcomingExhibition/Rock.jpg';
import FutureIcon3 from '../../media/upcomingExhibition/Sonata.jpg';
import ScrollToTop from "../ScrollToTop";
import Header from "../Header/Header";
import DonateFooter from "../Footer/DonateFooter";

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const UpcomingExhibition = ({mode, handleMode, setCategory}: ModeProps) => {
  return (
    <div className={`CurrentExhibition ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory} />
      <ScrollToTop />
      <h2><span>Upcoming Exhibition</span></h2>
      <div className="imageContainer">
        <img src={FutureIcon2} alt="Bow Fiddle Rock by Luna Smith" />
        <img src={FutureIcon1} alt="Odin's Reverie by Luna Smith" />
        <img src={FutureIcon3} alt="Fingal's Aquatic Sonata by Luna Smith" />
      </div>
      <h2>Chasing Light - a Scottish Impression.</h2>
      <p>
        Step into a world where the ethereal beauty of Scotland's landscapes comes to life through the masterful brushwork of Luna Smith. 'Chasing Light - A Scottish Impression' is an exquisite exhibition that invites you to immerse yourself in the breathtaking scenery of the Scottish highlands, coastlines, and urban vistas, all captured in Luna's unique impressionistic style. Her vivid and vibrant canvases, adorned with visible brushstrokes and a brilliant palette, breathe new life into the familiar. Luna's keen eye for the interplay of light and shadow paints a vivid, ever-changing tapestry of Scotland, where every stroke of her brush captures a moment in time. Experience the magic of Scotland's landscapes as Luna Smith takes you on an artistic journey through the heart and soul of her beloved homeland.
      </p>
      <DonateFooter mode={mode} />
    </div>
  );
};

export default UpcomingExhibition;
