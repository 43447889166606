import React from "react";
import Cancel from "../components/Cancel";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const cancel = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>cancel</title>
        <meta name="description" content="Your transaction was cancelled." />
        <meta name="keywords" content="art, art gallery, cancel, purchase, transaction" />
        <link rel="canonical" href="https://www.luartgallery.com/cancel" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/cancel" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <Cancel mode={mode} handleMode={handleMode} />
    </>
  );
};

export default cancel;