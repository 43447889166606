import { useState } from "react";
import LogoLight from "../../media/homepage/NewLogo2.png";
import LogoDark from "../../media/homepage/NewLogo.png";
import { FaBars, FaHome } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { FaRegLightbulb, FaMoon } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";
import { motion } from 'framer-motion';
import { FaShoppingBasket } from "react-icons/fa";
import { useBasket } from "../BasketContext";

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Header = ({mode, handleMode, setCategory}: ModeProps) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const { basket } = useBasket(); // Get basket from context
  const basketCount = basket.length;

  const toogleMenu = () => {
    setOpen(!open);
  };

  const handleMouseEnter = () => {
    setShowCategories(true);
  };

  const handleMouseLeave = () => {
    setShowCategories(false);
  };

  return (
    <div className={`Header ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
      <Link className="logoContainer" to="/">
        <img
          className="logo"
          src={mode !== 'light' ? LogoLight : LogoDark}
          alt="art gallery logo"
          title="LuArt Gallery logo"
          loading="lazy"
          width="320px/4" 
          height="216px/4"
        />
      </Link>
     {!open ? <div className="mobileIcon" onClick={toogleMenu}>
        <FaBars />
      </div> :
      <div className="mobileIcon" onClick={toogleMenu}>
        <IoClose />
      </div>}
      <motion.ul 
      className={open ? 'open' : 'closed'}
      initial={{y: -100}}
      animate={{y: 0}}
      transition={{duration: 1}}
      >
        <li>
          <Link to="/" className={location.pathname === "/" ? "active home" : "home"}><FaHome /></Link>
        </li>
        <li>
          <Link to="/about" className={location.pathname === "/about" ? "active" : ""}>ABOUT</Link>
        </li>
        <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
          <Link to="/art" className={location.pathname === "/art" ? "active" : ""}>ART</Link>
          {showCategories && (
            <motion.ul
              className="categories"
              initial={{opacity: 0, y: -40, x: 40}}
              animate={{opacity: 1, y: 0, x: 0}}
              transition={{duration: 0.5}}
            >
              <li onClick={() => setCategory('all')}><Link to="/art">All</Link></li>
              <li onClick={() => setCategory('landscapes')}><Link to="/art">Landscapes</Link></li>
              <li onClick={() => setCategory('animals')}><Link to="/art">Animals</Link></li>
              <li onClick={() => setCategory('nott')}><Link to="/art">Nott</Link></li>
              <li onClick={() => setCategory('soundoflife')}><Link to="/art">Sound of Life</Link></li>
              <li onClick={() => setCategory('portraits')}><Link to="/art">Portraits</Link></li>
              <li onClick={() => setCategory('shadows')}><Link to="/art">Shadows</Link></li>
              <li onClick={() => setCategory('abstracts')}><Link to="/art">Abstracts</Link></li>
              <li onClick={() => setCategory('stilllife')}><Link to="/art">Still Life</Link></li>
            </motion.ul>
          )}
        </li>
        <li>
          <Link to="/poems" className={location.pathname === "/poems" ? "active" : ""}>POEMS</Link>
        </li>
        <li>
          <Link to="/artist" className={location.pathname === "/artist" ? "active" : ""}>ARTIST</Link>
        </li>
        <li>
          <Link to="/contact" className={location.pathname === "/contact" ? "active" : ""}>Contact</Link>
        </li>
       <li> 
          <Link to="/basket" className="basket"><FaShoppingBasket /></Link>
          {basketCount > 0 && <span className="basketCount">{basketCount}</span>}
       </li>
        <li>
          <button className={`mode ${mode === 'light' ? 'lightMode' : 'darkMode'}`} onClick={handleMode}>
              {mode === 'light' && <span><FaRegLightbulb /></span>}
              {mode === 'dark' && <span><FaMoon /></span>}
              Mode
          </button>  
        </li>
      </motion.ul>
    </div>
  );
};

export default Header;
