import React from "react";

const Events = () => {
  return (
    <div className="Events">
      <h3>Events</h3>
      <div className="eventsContainer">
        <div>2023, 10 March - 22 March</div>
        <div> Solo exhibition at St Margaret House in Edinburgh, Scotland</div>
      </div>
      <div className="hiddenEventDescrition">
        Join us for the solo exhibition of fine art artist Luna Smith, where her
        unique and bold style comes to life on canvas. Known for her use of her
        fingers and strong visible brushstrokes, Smith's work showcases her
        mastery of color and texture. This exhibition offers a rare opportunity
        to witness the artist's creative process firsthand and to experience the
        emotion and energy imbued in each piece. Don't miss this chance to see
        the work of one of the most exciting emerging artists of our time.
      </div>
      <div className="eventsContainer">
        <div>2022/2023, 17 December - 10 January</div>
        <div>SSA 130 YEARS | ANNUAL EXHIBITION in Edinburgh, Scotland</div>
      </div>
      <div className="hiddenEventDescrition">
        “130th anniversary of the inaugural Society of Scottish Artists
        exhibition. To celebrate, we are taking a unique look at its vibrant
        history, and showcasing a selection of works that have contributed to
        Scotland’s artistic culture.”
        <br /> Luna Smith's artworks: Celtic Tale and Cute Little Things are
        part of this exhibition and available for sale in a 30x30cm section.
        <a
          href="https://www.s-s-a.org/annualexhibition2022/"
          aria-label="link to artcle about SSA 130 YEARS | ANNUAL EXHIBITION"
          target="_blank"
          rel="noreferrer"
        >
          article
        </a>
      </div>
      <div className="eventsContainer">
        <div>2022, permament exhibition</div>
        <div> Timeless Immortal Art in China: TIA-Huangshan</div>
      </div>
      <div className="hiddenEventDescrition">
        TIA-Huangshan gives immortality to artist with their works as
        testimonials of the streets of the town forever. An unprecedented
        combination of toponymy and art; Luna Smith's six artworks: The Guardian
        Tree, Diabaig, Italian Alps, Shimmering Golden Trees, Owl, and the
        Lonely Dragon have there a permanent home.
        <a
          href="https://www.artweek.com/art-news/timeless-immortal-art#"
          aria-label="link to artcle about Timeless Immortal Art project"
          target="_blank"
          rel="noreferrer"
        >
          article
        </a>
      </div>
      <div className="eventsContainer">
        <div>2021, permament exhibition</div>
        <div>MERÍ TIMELESS IMMORTAL ART - Sicily, Italy</div>
      </div>
      <div className="hiddenEventDescrition">
        Merì (Sicily) - Timeless Immortal Art", the revolution of the small town
        that becomes an eternal open-air museum, giving immortality to 60
        artists from all the nations of the world. A message for the future that
        fascinates all countries of the world. Luna Smith's Daibaig painting is
        part of this wonderful project.
        <a
          href="https://www.artweek.com/art-news/timeless-immortal-art#"
          aria-label="link to artcle about Timeless Immortal Art project"
          target="_blank"
          rel="noreferrer"
        >
          article
        </a>
        <a
          href="https://fineartshippers.com/timeless-immortal-art-how-to-turn-a-town-into-an-open-air-museum/"
          aria-label="link to artcle about Timeless Immortal Art project"
          target="_blank"
          rel="noreferrer"
        >
          article
        </a>
        <a
          href="https://youtu.be/V5NeTb57aF0"
          aria-label="link to YouTube vidoe about MERÍ TIMELESS IMMORTAL ART project in 2021"
          target="_blank"
          rel="noreferrer"
        >
          YouTube video
        </a>
      </div>
    </div>
  );
};

export default Events;
