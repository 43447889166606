import React from 'react';
import JoinSupportSection from './JoinSupportSection';
import QRCode from '../../media/QR code.png';

interface ModeProps {
  mode: string,
};

const DonateFooter = ({mode}:ModeProps) => {
  return (
    <div className={`DonateFooter ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
        <JoinSupportSection mode={mode} />
        <img src={QRCode} alt="QR code to donate to artist, Luna Smith from LuArt Gallery" title="QR code" loading="lazy" width="128px" height="128px"/>
        <button className='homeDonate'><a href="https://www.paypal.com/donate/?hosted_button_id=868S6V2VZ22HE">Donate</a></button>
    </div>
  )
}

export default DonateFooter