import React from "react";
import Art from "../components/Art/Art";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

const art = ({mode, handleMode, category, setCategory}) => {
  return (
    <>
      <Helmet>
        <title>art</title>
        <meta name="description" content="Scottish contemporary fine art is a vibrant and diverse genre that encompasses a wide range of styles and subject matter." />
        <meta name="keywords" content="art, art gallery, gallery, exhibition, contemporary, Scottish art, Scottish artist, Edinburgh, Scotland, fine art, painting, oil paintings, buy art, invest in art, home decor, exhibition, artist, Luna Smith, LuArt Gallery" />
        <link rel="canonical" href="/art" />
      </Helmet>
      <ScrollToTop />
      <Art mode={mode} handleMode={handleMode} category={category} setCategory={setCategory} />
    </>
  );
};

export default art;