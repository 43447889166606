import React from 'react';
import DonateFooter from '../Footer/DonateFooter';
import ArtGalleryLogoAd from '../../media/homepage/Logo.mp4';

interface ModeProps {
  mode: string,
};

const AboutSection = ({mode}: ModeProps) => {

  return (
    <div className={`AboutSection ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
      <div>
        <h1>Welcome to <span>LuArt Gallery</span></h1>
        <p>LuArt Gallery is a contemporary art gallery located in the heart of Edinburgh, Scotland. We are dedicated to showcasing the best in contemporary Scottish art, featuring a diverse range of subjects and mediums.</p>
        <p>We believe that art has the power to inspire, challenge, and connect people from all walks of life. That's why we strive to create a welcoming and inclusive space where everyone can discover and appreciate the beauty and creativity of artists.</p>
        <p>Our gallery features a rotating selection of exhibitions that highlight the work of emerging Scottish artist, Luna Smith. From painting and sculpture to poems and mixed media, our exhibitions offer a diverse range of styles and mediums to explore. We also host artist talks, workshops, and other events that give visitors an opportunity to learn more about the artist and her work.</p>
        <p>In addition to our exhibitions, we also have a well-curated selection of artworks available for purchase. Whether you're a seasoned collector or a first-time buyer, our knowledgeable staff is always on hand to help you find the perfect piece for your home or office.</p>
        <p>We are committed to supporting the growth and development of the Scottish contemporary art scene. We believe that by fostering a community of artists, collectors, and art enthusiasts, we can help to create a vibrant and thriving arts culture in Scotland.</p>
        <p>If you're looking for a unique and inspiring art experience, we invite you to visit LuArt Gallery. We look forward to welcoming you soon!</p>
      </div>
      <video src={ArtGalleryLogoAd} autoPlay={true} controls={true} loop={true}/>
      <DonateFooter mode={mode}/>
    </div>
  )
}

export default AboutSection