import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

interface ContentProps {
  id: number;
  src: string;
  alt: string;
  about: string;
  title: string;
  description: string;
  button: string;
  link: string;
  mode: string,
}

const Section: React.FC<ContentProps> = ({
  src,
  alt,
  title,
  description,
  id,
  button,
  about,
  link,
  mode
}) => {
  if (id % 2 === 0) {
    return (
      <motion.div 
      className={`Section1 Section ${mode === 'light' ? 'lightMode' : 'darkMode'}`}
      initial={{ x: window.innerWidth > 768 ? "-300px" : "-15px"}}
      whileInView={{x: 0}}
      transition={{ duration: 0.5}}>
        <Link to={link}>
          <img src={src} alt={alt} title={title} className="mainImage" loading="lazy" width="auto" height="auto" />
        </Link>
        <div>
          <h2>{about}</h2>
          <h3>{title}</h3>
          <h4>{description}</h4>
          <Link to={link}>
            <button className="sectionButton">{button}</button>
          </Link>
        </div>
      </motion.div>
    );
  } else {
    return (
      <motion.div 
      className={`Section2 Section ${mode === 'light' ? 'lightMode' : 'darkMode'}`}
      initial={{ x: window.innerWidth > 768 ? "300px" : "15px"}}
      whileInView={{x: 0}}
      transition={{ duration: 0.5}}>
        <div>
          <h2>{about}</h2>
          <h3>{title}</h3>
          <h4>{description}</h4>
          <Link to={link}>
            <button className="sectionButton">{button}</button>
          </Link>
        </div>
        <Link to={link}>
          <img src={src} alt={alt} title={title} className="mainImage" loading="lazy" width="auto" height="auto" />
        </Link>
      </motion.div>
    );
  }
};

export default Section;
