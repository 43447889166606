import React from "react";
import Volunteers from "../components/Footer/Volunteers";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const volunteers = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>volunteers</title>
        <meta name="description" content="Join the LuArt Gallery team as a volunteer and make a difference in the contemporary art world. Gain valuable experience and contribute to our mission of sharing the transformative power of art" />
        <meta name="keywords" content="art, art gallery, gallery, exhibition, contemporary, Scottish art, Edinburgh, Scotland, fine art, painting, volunteer, job, work, LuArt Gallery" />
        <link rel="canonical" href="https://www.luartgallery.comvolunteerspoems" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/volunteers" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <Volunteers mode={mode} handleMode={handleMode} />
    </>
  );
};

export default volunteers;