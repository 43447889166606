import React from 'react'
import Footer from './Footer'
import Header from '../Header/Header'
import ScrollToTop from '../ScrollToTop'
import ReturnSection from './ReturnSection'

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const ReturnTerms = ({mode, handleMode, setCategory}: ModeProps) => {
  return (
    <>
      <ScrollToTop />
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory}/>
      <ReturnSection />
      <Footer mode={mode} />
  </>
  )
}

export default ReturnTerms