import { useState, useEffect } from "react";
import { poemsCollection } from "../../content/poemsColletion";
import Feather from "../../media/feather.png";
import { motion } from "framer-motion";

interface PoemProps {
    title: string,
    author: string,
    verses: string[],
}

const Poems = () => {
    const [xValue, setXValue] = useState({ left: -230, right: 230 });
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 768) {
          setXValue({ left: -150, right: 150 });
        } else {
          setXValue({ left: -230, right: 230 });
        }
      };
  
      handleResize(); // Set initial values based on screen size
      window.addEventListener("resize", handleResize);
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return (
      <div className='Poems'>
          {poemsCollection.map((poem: PoemProps, index: number) => (
              <div className="poemWrapper" key={index}>
              <motion.div
                  initial={{ x: 0 }}
                  whileInView={{ x: [0, xValue.left] }}
                  transition={{ duration: 3, ease: "easeInOut" }}
                  className="topHalf"
              ></motion.div>
              <motion.div
                  initial={{ x: 0 }}
                  whileInView={{ x: [0, xValue.right] }}
                  transition={{ duration: 3, ease: "easeInOut" }}
                  className="bottomHalf"
              ></motion.div>
            <div 
            className="poemContainer">
            <h3>{poem.title}</h3>
            {poem.verses.map((verse, i) => (
                <p key={i}>
                    {verse}
                    {i < poem.verses.length - 1 && <br />}
                </p>
            ))}
            <div className="authorContainer">
                <h5>{poem.author}</h5>
                <img src={Feather} alt="feather" className="featherAuthor" title="feather" loading="lazy" width="60px" height="60px" />
            </div>
        </div>
        </div>
        ))}
    </div>
  )
}

export default Poems