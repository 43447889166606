import React, {useState} from 'react';
import DonateFooter from './DonateFooter';

interface ReviewFormData {
    name: string;
    review: string;
 }

interface ModeProps {
  mode: string,
};

const ReviewSection = ({mode}:ModeProps) => {

const [submit, setSubmit] = useState(false);

const [reviewFormData, setReviewFormData] = useState<ReviewFormData>({ name: '', review: '' });

const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetch('http://luartgallery.com/form', {
      method: 'POST',
      body: JSON.stringify(reviewFormData),
    });
    setSubmit(true);
  };

  return (
    <div className='ReviewSection'>
      <h2>Thank you for visiting LuArt Gallery</h2>
      {!submit ? 
      <form className='reviewForm' onSubmit={handleSubmit}>
        <p>We hope you had a wonderful experience exploring our collection of art and exhibitions. We would love to hear your thoughts and feedback on your visit.</p>
            <input
                type="text"
                id="name"
                value={reviewFormData.name}
                placeholder="enter your name..."
                required
                onChange={(event) =>
                setReviewFormData({ ...reviewFormData, name: event.target.value })
                }
            />
            <textarea
                id="review"
                name="review"
                value={reviewFormData.review}
                placeholder="write your review..."
                required
                onChange={(event) =>
                setReviewFormData({ ...reviewFormData, review: event.target.value })
                }
            />
             <div className='checkbox'>
                <input type="checkbox" id="checkbox" />
                <label htmlFor="checkbox">I am not a robot</label>
            </div>
            <button type="submit">Submit</button>
      </form> : 
      <p>Thank you for your support and for taking the time to leave a review. Your feedback is important to us, and we look forward to hearing from you soon.</p>}
      <DonateFooter mode={mode}/>
    </div>
  )
}

export default ReviewSection