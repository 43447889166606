import React from "react";
import Shop from "../components/Shop";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

const shop = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>shop</title>
        <meta name="description" content="Shop for fine art at LuArt Gallery. Our online shop features a curated selection of paintings, sculptures, and mixed media works from a talented Scottish artist, Luna Smith. Browse our collection to find the perfect piece to brighten up your life." />
        <meta name="keywords" content="art, art gallery, gallery, exhibition, contemporary, Scottish art, Edinburgh, Scotland, fine art, painting, oil paintings, shop, purchase, investment, transaction, LuArt Gallery" />
        <link rel="canonical" href="/shop" />
      </Helmet>
      <ScrollToTop />
      <Shop mode={mode} handleMode={handleMode} />
    </>
  );
};

export default shop;