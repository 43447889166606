import React from "react";
import ContactForm from "./ContactForm";
import DonateFooter from "../Footer/DonateFooter";

interface ModeProps {
  mode: string,
};

const ContactSection = ({mode}: ModeProps) => {
  return (
    <div className={`ContactSection ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
      <h1>Contact Us <span> - send a message</span></h1>
      <ContactForm />
      <DonateFooter mode={mode}/>
    </div>
  );
};

export default ContactSection;
