import React from 'react';
import { abstractsCollection } from '../../content/artpageAbstracts';
import ArtGallery from './ArtGallery';

interface PaintingProps {
    src: string;
    srcBg: string;
    alt: string;
    about: string;
    title: string;
    description: string;
    button: string;
    price: string;
    link: string;
    linkPayPal: string;
    dimension: string;
    keyWords: string;
  }

const Abstracts = () => {
  return (
    <div className='artCollectionContainer'>
    <h2>Abstracts</h2>
    <p>Abstracts are a doorway into the boundless realms of imagination and emotion, an exploration of the unseen and the unknown. The bold shapes, vibrant colors, and dynamic forms come together to create a symphony of visual expression that transcends the literal and the tangible. The canvas becomes a playground for creativity, capturing the essence of feelings and ideas in their purest form, free from the constraints of reality. Each stroke, each splash of paint, invites viewers to embark on a personal journey of interpretation and introspection, unlocking the infinite possibilities of thought and perception. Abstracts remind us to embrace the beauty of ambiguity, to find meaning in the mysterious, and to celebrate the profound complexity of our inner worlds. They encourage us to see beyond the surface, to connect with the deeper currents of our emotions, and to appreciate the limitless potential of artistic expression.</p>
    {abstractsCollection.map((abstractPainting: PaintingProps) => {
      return (
        <div key={abstractPainting.title}>
             <ArtGallery 
                about={abstractPainting.about} 
                alt={abstractPainting.alt} 
                description={abstractPainting.description}
                price={abstractPainting.price}
                title={abstractPainting.title}
                src={abstractPainting.src}
                srcBg={abstractPainting.srcBg}
                button={abstractPainting.button}
                link={abstractPainting.link}
                linkPayPal={abstractPainting.linkPayPal}
                dimension={abstractPainting.dimension}
                keyWords={abstractPainting.keyWords}
         />
        </div>
      )
    })}
    </div>
  )
}

export default Abstracts