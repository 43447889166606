import React from 'react';
import { shadowsCollection } from '../../content/artpageShadows';
import ArtGallery from './ArtGallery';

interface PaintingProps {
    src: string;
    srcBg: string;
    alt: string;
    about: string;
    title: string;
    description: string;
    button: string;
    price: string;
    link: string;
    linkPayPal: string;
    dimension: string;
    keyWords: string;
  }

const Shadows = () => {
  return (
    <>
    <h2>Shadows</h2>
    <div className='shadowPoem'>
      <div>I am a standing shadow, hidden, among the stillness of life.</div>
      <div>I am a walking shadow, I follow, blind and voiceless.</div>
      <div>I am a running shadow, lost, overwhelmed, and yet, full of hope.</div>
      <div>I am a dancing shadow, I sing, I dream, I spread my wings.</div>
      <div>I am a flying shadow, soaring into the unknown,</div>
      <div>I shed my fears and live my life, free.</div>
    </div>
    <p>Step into the captivating world of Luna Smith's shadow art collection. Created by a talented Scottish painter, these mesmerizing paintings in different shades of blue, light yellow, and white transport you to a secret realm of humanity. Each piece is a window into the artist's personal journey, exploring the feelings of invisibility and the longing to be seen and not seen at the same time. These artworks are more than just visual masterpieces, they are a reflection of the human experience, inviting you to connect with the artist's emotions and thoughts. Let this collection be a source of inspiration and wonder, as you discover the beauty of the unseen.</p>
    {shadowsCollection.map((shadowPainting: PaintingProps, index: number) => {
      if (index === 0) {
        return null;
      } else {
        return (
          <div key={index}>
               <ArtGallery 
                  about={shadowPainting.about} 
                  alt={shadowPainting.alt} 
                  description={shadowPainting.description}
                  price={shadowPainting.price}
                  title={shadowPainting.title}
                  src={shadowPainting.src}
                  srcBg={shadowPainting.srcBg}
                  button={shadowPainting.button}
                  link={shadowPainting.link}
                  linkPayPal={shadowPainting.linkPayPal}
                  dimension={shadowPainting.dimension}
                  keyWords={shadowPainting.keyWords}
           />
          </div>
        )
      }

    })}
    </>
  )
}

export default Shadows