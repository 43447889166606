import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import Contact from "./pages/contact";
import Subscribe from "./pages/subscribe";
import Cancelled from "./pages/cancel";
import Completed from "./pages/completed";
import About from "./pages/about";
import Exhibitions from './pages/exhibitions';
import Art from './pages/art';
import ArtworkPage from './pages/artwork';
import ImagePage from './pages/image';
import Artist from './pages/artist';
import Shop from './pages/shop';
import Terms from './pages/terms';
import Returns from './pages/returns';
import ReturnForm from './pages/returnForm';
import Poems from './pages/poems';
import Reviews from './pages/reviews';
import Jobs from './pages/jobs';
import Volunteers from './pages/volunteers';
import UpcomingExhibition from './pages/upcomingexhibitions';
import Basket from './components/Basket';
import { BasketProvider } from './components/BasketContext';

function App() {

  const [mode, setMode] = useState<string>('dark');

  const handleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const [category, setCategory] = useState('all');

  return (
  <BasketProvider>
      <Router>
          <Routes>
            <Route path="/" element={<Home mode={mode} handleMode={handleMode} setCategory={setCategory} />} />
            <Route path="/about" element={<About mode={mode} handleMode={handleMode} />} />
            <Route path="/art" element={<Art mode={mode} handleMode={handleMode} category={category} setCategory={setCategory}  />} />
            <Route path="/art/:title" element={<ArtworkPage mode={mode} />} />
            <Route path="/art/:title.jpg" element={<ImagePage mode={mode} handleMode={handleMode} />} />
            <Route path="/exhibitions" element={<Exhibitions mode={mode} handleMode={handleMode} />} />
            <Route path="/upcomingexhibitions" element={<UpcomingExhibition mode={mode} handleMode={handleMode} />} />
            <Route path="/poems" element={<Poems mode={mode} handleMode={handleMode} />}  />
            <Route path="/artist" element={<Artist mode={mode} handleMode={handleMode} />} />
            <Route path="/shop" element={<Shop mode={mode} handleMode={handleMode} />} />
            <Route path="/basket" element={<Basket mode={mode} handleMode={handleMode} setCategory={setCategory}/>} />
            <Route path="/contact" element={<Contact mode={mode} handleMode={handleMode} />} />
            <Route path="/subscribe" element={<Subscribe mode={mode} handleMode={handleMode} />} />
            <Route path="/cancel" element={<Cancelled mode={mode} handleMode={handleMode} />} />
            <Route path="/completed" element={<Completed mode={mode} handleMode={handleMode} />} />
            <Route path="/volunteers" element={<Volunteers mode={mode} handleMode={handleMode} />} />
            <Route path="/jobs" element={<Jobs mode={mode} handleMode={handleMode} />} />
            <Route path="/reviews" element={<Reviews mode={mode} handleMode={handleMode} />} />
            <Route path="/terms" element={<Terms mode={mode} handleMode={handleMode} />} />
            <Route path="/returns" element={<Returns mode={mode} handleMode={handleMode} />} />
            <Route path="/return/form" element={<ReturnForm mode={mode} handleMode={handleMode} />} />
          </Routes>
      </Router>
    </BasketProvider>
  );
}

export default App;
