import React from 'react';
import { landscapesCollection } from '../../content/artpageLandscape';
import { animalsCollection, hatchingEggsCollection } from "../../content/artpageAnimals";
import { nottCollectionBuy } from "../../content/artpageNott";
import { shadowsCollection } from '../../content/artpageShadows';
import { soundOfLifeCollection } from "../../content/artpageSoundOfLife";
import { portraitsCollection } from "../../content/artpagePortrait";
import ArtGallery from './ArtGallery';
import { abstractsCollection } from '../../content/artpageAbstracts';

interface PaintingProps {
    src: string;
    srcBg: string;
    alt: string;
    about: string;
    title: string;
    description: string;
    button: string;
    price: string;
    link: string;
    linkPayPal: string;
    dimension: string;
    keyWords: string;
  }

  const renderPaintings = (collection: PaintingProps[], filterFunction: (painting: PaintingProps) => boolean) => {
    return collection.filter(filterFunction).map((painting: PaintingProps, index: number) => (
      <div key={index}>
        <ArtGallery
          about={painting.about}
          alt={painting.alt}
          description={painting.description}
          price={painting.price}
          title={painting.title}
          src={painting.src}
          srcBg={painting.srcBg}
          button={painting.button}
          link={painting.link}
          linkPayPal={painting.linkPayPal}
          dimension={painting.dimension}
          keyWords={painting.keyWords}
        />
      </div>
    ));
  };
  
  const AllPaintings: React.FC<{ filterFunction: (painting: PaintingProps) => boolean }> = ({ filterFunction }) => {
    return (
      <div className='searchPainting'>
        <h2>Searched Painting</h2>
        {renderPaintings(landscapesCollection, filterFunction)}
        {renderPaintings(nottCollectionBuy, filterFunction)}
        {renderPaintings(hatchingEggsCollection, filterFunction)}
        {renderPaintings(animalsCollection, filterFunction)}
        {renderPaintings(shadowsCollection, filterFunction)}
        {renderPaintings(soundOfLifeCollection, filterFunction)}
        {renderPaintings(portraitsCollection, filterFunction)}
        {renderPaintings(abstractsCollection, filterFunction)}
      </div>
    );
  };
  
  export default AllPaintings;
