import React from "react";
import Artist from "../components/Artist/Artist";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

const artist = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>artist</title>
        <meta name="description" content="Luna Smith - a Scottish contemporary painter from Edinburgh. The artsit who can hear the music. She specializes in fine art and oils." />
        <meta name="keywords" content="art, art gallery, gallery, exhibition, contemporary, Scottish art, Scottish artist, Edinburgh, Scotland, fine art, painting, oil paintings, buy art, invest in art, home decor, exhibition, artist, Luna Smith, LuArt Gallery" />
        <link rel="canonical" href="/artist" />
      </Helmet>
      <ScrollToTop />
      <Artist mode={mode} handleMode={handleMode} />
    </>
  );
};

export default artist;