import React from "react";
import Exhibitions from "../components/WhatsOn/Exhibitions";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";

const exhibitions = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>exhibitions</title>
        <meta name="description" content="Discover the latest exhibitions at LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland." />
        <meta name="keywords" content="art, art gallery, gallery, exhibition, contemporary, Scottish art, Scottish artist, Edinburgh, Scotland, fine art, painting, oil paintings, buy art, invest in art, home decor, exhibition, artist, Luna Smith, LuArt Gallery" />
        <link rel="canonical" href="/exhibitions" />
      </Helmet>
      <ScrollToTop />
      <Exhibitions mode={mode} handleMode={handleMode} />
    </>
  );
};

export default exhibitions;