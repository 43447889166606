import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Product {
  src: string;
  alt: string;
  title: string;
  dimension: string;
  price: string;
}

interface BasketContextType {
  basket: Product[];
  addToBasket: (product: Product) => void;
  removeFromBasket: (title: string) => void;
}

interface BasketProviderProps {
  children: ReactNode;
}

const BasketContext = createContext<BasketContextType | undefined>(undefined);

export const useBasket = () => {
  const context = useContext(BasketContext);
  if (!context) {
    throw new Error('useBasket must be used within a BasketProvider');
  }
  return context;
};

export const BasketProvider: React.FC<BasketProviderProps> = ({ children }) => {
  const [basket, setBasket] = useState<Product[]>([]);

  const addToBasket = (product: Product) => setBasket([...basket, product]);

  const removeFromBasket = (title: string) => setBasket(basket.filter(item => item.title !== title));

  return (
    <BasketContext.Provider value={{ basket, addToBasket, removeFromBasket }}>
      {children}
    </BasketContext.Provider>
  );
};