import React from "react";
import { Link } from "react-router-dom";
import DonateFooter from "./DonateFooter";

interface ModeProps {
  mode: string,
};

const VolunteersSection = ({mode}:ModeProps) => {
  return (
    <div className="VolunteersSection">
      <h2>Volunteer at LuArt Gallery</h2>
      <p>Dear Volunteers,</p>
      <p>
        Exciting news! New opportunities for growth and involvement are on the
        horizon.
      </p>
      <p>
        {" "}
        - We are looking for 2 volunteers to help with fine art exhibition in
        Edinburgh, at St Margaret House (date: 23th of March).
      </p>
      <p>
        {" "}
        - We are looking for musicians, poets and other performers to perform at
        the exhibition in Edinburgh, at St Margaret House (dates: 10th - 22th of
        March).
      </p>
      <p>Please contact us for more details.</p>
      <p>Best regards, LuArt Gallery.</p>
      <button>
        <Link to="/contact">Contact us</Link>
      </button>
      <DonateFooter mode={mode} />
    </div>
  );
};

export default VolunteersSection;
