import React from 'react';
import ExhibitionSection from './ExhibitionSection';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ScrollToTop from '../ScrollToTop';

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Exhibitions = ({mode, handleMode, setCategory}:ModeProps) => {
  return (
    <>
      <ScrollToTop />
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory} />
      <ExhibitionSection mode={mode} />
      <Footer mode={mode}/>
    </>
  )
}

export default Exhibitions



