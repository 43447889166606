import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

const ReturnForm: React.FC = () => {
  const form = useRef<any>();
  const [submit, setSubmit] = useState(false);


  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wnq7xgs",
        "template_jybanxb",
        form.current,
        "cdMexHjomTqiCa1rY"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmit(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="ReturnForm">
      <Link to='/'><div className='close'>X</div></Link>
      <form ref={form} onSubmit={sendEmail}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="user_name"
            placeholder="enter your name..."
            required
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="user_email"
            placeholder="enter your email..."
            required
          />
          <label htmlFor="message">Reason for return</label>
          <br />
          <textarea
            name="user_message"
            placeholder="enter your message..."
            required
          />
        <div className="checkbox">
          <input type="checkbox" id="checkbox" required />
          <label htmlFor="checkbox">I am not a robot</label>
        </div>
        <input type="submit" value="Send" className="formButton" /> 
        {!submit ? (
          <p className="lackResponse"></p>
        ) : (
          <p className="response">
            <span>
              <strong>
              Thank you. Please return the item within 30 days of receiving it. The item must be in its original condition. A refund will be issued once the item has been returned.
              </strong>
            </span>
            <div>
            <span>
              <strong>
              Address: LuArt Gallery, 14B Orwell Terrace, EH11 2DY, Edinburgh, Scotland.
              </strong>
            </span>
            </div>
          </p>
        )}
      </form>
    </div>
  );
};

export default ReturnForm;