import React from 'react';
import JobsSection from './JobsSection';
import Footer from './Footer';
import Header from '../Header/Header';
import ScrollToTop from '../ScrollToTop';

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Jobs = ({mode, handleMode, setCategory}: ModeProps) => {
  return (
    <>
      <ScrollToTop />
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory}/>
      <JobsSection mode={mode}/>
      <Footer mode={mode}/>
    </>
  )
}

export default Jobs