import { useState } from 'react';
import LogoLight from "../../media/homepage/NewLogo2.png";
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
} from "react-icons/fa";

import { Link } from 'react-router-dom';

interface ModeProps {
  mode: string,
};

const Footer = ({mode}:ModeProps) => {

const [open, setOpen] = useState(false);

const handleOpenClick = () => {
  setOpen(!open);
}

  return (
    <div className={`Footer ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
      <div className='container'>
        <ul>
          <ul>
            <li className='titleList'>Enquiries</li>
            <li><Link to = "/exhibitions">Exhibitions</Link></li>
            <li><Link to = "/volunteers">Volunteer</Link></li>
            <li><Link to = "/jobs">Work for us</Link></li>
          </ul>
          <ul>
            <li className='titleList'>Opening Times</li>
            <li><span>Saturday</span><span>10am - 1pm</span></li>
            <li><span>Sunday</span><span>10am - 1pm</span> </li>
          </ul>
          <ul>
            <li className='titleList'>Stay in touch</li>
            <li><Link to = "/contact">Contact us</Link></li>
            <li><Link to = "/reviews">Reviews</Link></li>
            <li className='socialMedia'>
              <button onClick={handleOpenClick}>Social Media</button>
              <div className='socialIcons' style={!open ? {display: "none"} : {display: "flex"}}>
                <a href="//www.facebook.com/lunasmithart" target="_blank" aria-label="Facebook page" rel="noreferrer">
                  <FaFacebook />
                </a>
                <a href="//twitter.com/LuArtGallery" target="_blank" aria-label="Facebook page" rel="noreferrer">
                  <FaTwitter />
                </a>
                <a href="//www.instagram.com/luartgallery" target="_blank" aria-label="Facebook page" rel="noreferrer">
                  <FaInstagram />
                </a>
              </div>
            </li>
          </ul>
        </ul>
        <div>
          <div className='terms'><Link to = "/terms">Gallery Terms</Link></div>
          <div className='terms'><Link to = "/returns">Return Policy</Link></div>
        </div>
        <Link className='logoContainer' to = "/">
          <img className='logo' 
            src={LogoLight}
            alt="LuArt Gallery logo" 
            title="LuArt Gallery logo" 
            loading="lazy" 
            width="320px/4" 
            height="216px/4" />
        </Link>
      </div>
      <div className='copyRights'>©️ {new Date().getFullYear()} LuArt Gallery, Edinburgh, Scotland.</div>
    </div>
  )
}

export default Footer