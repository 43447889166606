import React from "react";
import { soundOfLifeCollection } from "../../content/artpageSoundOfLife";
import ArtGallery from "./ArtGallery";

interface PaintingProps {
  src: string;
  srcBg: string;
  alt: string;
  about: string;
  title: string;
  description: string;
  button: string;
  price: string;
  link: string;
  linkPayPal: string;
  dimension: string;
  keyWords: string;
}

const SoundofLife = () => {
  return (
    <>
      <h2>Sound of Life</h2>
      <p>
        "When I listen to music, every sound, every rhythm has a unique colour
        and texture. I see melody dancing, changing like the northern lights in
        the sky. My brushstrokes aspire to capture every note." Luna Smith
      </p>
      {soundOfLifeCollection.map(
        (soundOfLifePainting: PaintingProps, index: number) => {
          return (
            <div key={index}>
              <ArtGallery
                about={soundOfLifePainting.about}
                alt={soundOfLifePainting.alt}
                description={soundOfLifePainting.description}
                price={soundOfLifePainting.price}
                title={soundOfLifePainting.title}
                src={soundOfLifePainting.src}
                srcBg={soundOfLifePainting.srcBg}
                button={soundOfLifePainting.button}
                link={soundOfLifePainting.link}
                linkPayPal={soundOfLifePainting.linkPayPal}
                dimension={soundOfLifePainting.dimension}
                keyWords={soundOfLifePainting.keyWords}
              />
            </div>
          );
        }
      )}
    </>
  );
};

export default SoundofLife;
