import React from 'react';
import { Link } from 'react-router-dom';

const ReturnSection: React.FC = () => {

  return (
    <div className='ReturnSection'>
      <h2>Return Policy</h2>
      <h3>Thank you for choosing our handmade paintings to enhance your living space. We take great pride in creating unique and exquisite artworks, and we want you to be completely satisfied with your purchase. Please review our return policy below for information on returning or exchanging your handmade painting.</h3>
      <ul>
        <li className='title'>Eligibility for Returns:</li>
        <li>You may return a handmade painting within 30 days from the date of delivery.</li>
        <li>To be eligible for a return, the painting must be in its original condition, including the frame and packaging.</li>
        <li>Paintings that have been damaged or altered in any way will not be eligible for return.</li>
      </ul>
      <ul>
        <li className='title'>Initiate the Return Process:</li>
        <li>To initiate a return, please send the return form within the 30-day return window.</li>
        <li><Link to="/return/form"><button>Return Form</button></Link></li>
        <li>Provide your order number, the name of the painting, and the reason for the return.</li>
      </ul>
      <ul>
        <li className='title'>Return Shipping:</li>
        <li>You are responsible for the cost of return shipping unless the painting arrived damaged or there was an error on our part.</li>
        <li>We recommend using a trackable and insured shipping method to ensure the safe return of the painting.</li>
      </ul>
      <ul>
        <li className='title'>Refund or Exchange:</li>
        <li>Once we receive the returned painting and verify its condition, we will process your refund or exchange.</li>
        <li>Refunds will be issued to the original payment method within 7 of business days.</li>
        <li>Exchanges will be processed promptly, and we will cover the cost of shipping for the replacement painting if applicable.</li>
      </ul>
      <ul>
        <li className='title'>Damaged or Incorrect Items:</li>
        <li>If you receive a damaged painting or an incorrect item, please contact us immediately.</li>
        <li>We will provide instructions for returning the item, and we will cover the return shipping costs.</li>
        <li>You may choose to receive a replacement or a full refund in such cases.</li>
      </ul>
      <ul>
        <li className='title'>Non-Returnable Items:</li>
        <li>Custom-made or personalized paintings are non-returnable unless they arrive damaged or contain errors on our part.</li>
      </ul>
      <ul>
        <li className='title'>Cancellation Policy:</li>
        <li>You may cancel your order within 24 hours after placing it. Please contact us immediately if you wish to cancel your order.</li>
      </ul>
      <h3>We appreciate your trust in our handmade paintings, and we are committed to providing you with the highest quality products and exceptional customer service. If you have any concerns or issues with your purchase, please do not hesitate to reach out to us, and we will do our best to resolve them.
      <br/><br/>Thank you for choosing our handmade paintings to adorn your surroundings.<br/><br/>Sincerely,<br/>LuArt Gallery
      </h3>
    </div>

  )
}

export default ReturnSection