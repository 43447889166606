import ArtStudio1 from "../media/artsudio/Luna Smith in her art studio 1.jpg";
import ArtStudio2 from "../media/artsudio/Luna Smith in her art studio 2.jpg";
import ArtStudio3 from "../media/artsudio/Luna Smith in her art studio 3.jpg";
import ArtStudio4 from "../media/artsudio/Luna Smith in her art studio 4.jpg";
import ArtStudio5 from "../media/artsudio/Luna Smith in her art studio 5.jpg";
import ArtStudio6 from "../media/artsudio/Luna Smith in her art studio 6.jpg";
import ArtStudio7 from "../media/artsudio/Luna Smith in her art studio 7.jpg";
import ArtStudio8 from "../media/artsudio/Luna Smith in her art studio 8.jpg";
import ArtStudio9 from "../media/artsudio/Luna Smith in her art studio 9.jpg";
import ArtStudio10 from "../media/artsudio/Luna Smith in her art studio 10.jpg";
import ArtStudio11 from "../media/artsudio/Luna Smith in her art studio 11.jpg";
import ArtStudio12 from "../media/artsudio/Luna Smith in her art studio 12.jpg";
import ArtStudio13 from "../media/artsudio/Luna Smith in her art studio 13.jpg";
import ArtExhibition1 from "../media/artsudio/Luna Smith solo art exhibition in Edinburgh 1.jpg";
import ArtExhibition2 from "../media/artsudio/Luna Smith solo art exhibition in Edinburgh 2.jpg";
import ArtExhibition3 from "../media/artsudio/Luna Smith solo art exhibition in Edinburgh 3.jpg";
import ArtExhibition4 from "../media/artsudio/Luna Smith solo art exhibition in Edinburgh 4.jpg";

export const artstudio = [
  {
    id: 1,
    src: ArtStudio1,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 2,
    src: ArtStudio2,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 3,
    src: ArtStudio3,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 4,
    src: ArtStudio4,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 5,
    src: ArtStudio5,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 6,
    src: ArtStudio6,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 7,
    src: ArtStudio7,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 8,
    src: ArtStudio8,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 9,
    src: ArtStudio9,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 10,
    src: ArtStudio10,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 11,
    src: ArtStudio11,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 12,
    src: ArtStudio12,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 13,
    src: ArtStudio13,
    alt: "Luna Smith in her art studio",
    title: "Luna Smith in her art studio",
  },
  {
    id: 14,
    src: ArtExhibition1,
    alt: "Luna Smith solo art exhibition in Edinburgh, 2023",
    title: "Luna Smith solo art exhibition in Edinburgh, 2023",
  },
  {
    id: 15,
    src: ArtExhibition2,
    alt: "Luna Smith solo art exhibition in Edinburgh, 2023",
    title: "Luna Smith solo art exhibition in Edinburgh, 2023",
  },
  {
    id: 16,
    src: ArtExhibition3,
    alt: "Luna Smith solo art exhibition in Edinburgh, 2023",
    title: "Luna Smith solo art exhibition in Edinburgh, 2023",
  },
  {
    id: 17,
    src: ArtExhibition4,
    alt: "Luna Smith solo art exhibition in Edinburgh, 2023",
    title: "Luna Smith solo art exhibition in Edinburgh, 2023",
  },
];
