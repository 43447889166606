import React from 'react';
import { homepage } from '../../content/hompage';
import Section from './Section';
import DonateFooter from '../Footer/DonateFooter';

interface ContentProps {
    id: number;
    src: string;
    alt: string;
    about: string;
    title: string;
    description: string;
    button: string;
    link: string;
  }

  interface ModeProps {
    mode: string,
  };

const Main = ({mode}:ModeProps)=> {

  return (
    <div className={`Main ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
      {homepage.map((content: ContentProps, index: number) => {
          return (
            <div key={index}>
              <Section 
                src={content.src} 
                alt={content.alt} 
                title={content.title} 
                description={content.description}
                id={content.id}
                about={content.about}
                button={content.button}
                link={content.link}
                mode={mode}
              />
            </div>
          )
      })}
      <DonateFooter mode={mode} />
    </div>
  )
}

export default Main