import React from 'react';
import Footer from './Footer/Footer';
import Header from './Header/Header';

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Cancel = ({mode, handleMode, setCategory}: ModeProps) => {
  return (
    <div className='Cancel'>
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory}/>
      <h2>Your transaction was cancelled.</h2>
      <Footer mode={mode}/>
    </div>
  )
}

export default Cancel