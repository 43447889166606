import React from 'react';
import ScrollToTop from '../ScrollToTop';
import NowIcon from '../../media/upcomingExhibition/ExhibitionIconNow.jpg';
import QRCode from '../../media/QR code.png';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PoemsSection from './PoemsSection';

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Poems = ({mode, handleMode, setCategory}:ModeProps) => {
  return (
    <div className={`Poems ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
        <ScrollToTop />
        <Header mode={mode} handleMode={handleMode} setCategory={setCategory} />
        <h1>Scottish Poetry <span>by Humanlist</span></h1>
        <h4>We invite you to support the vibrant tradition of Scottish poetry. Your sponsorship or donation will help us continue to nurture and celebrate this creative art form. Contribute to the future of Scottish poetry today.</h4>
        <div className='donateContainer'>
          <div>
            <img src={NowIcon} 
            alt="the poster of Scottish Poetry Exhibition" 
            className='MainPoster'   
            title="Scottish Poetry poster" 
            loading="lazy" 
            width="240px"
            height="300px"/>
          </div>
          <div className='DonateFooter'>
              <img src={QRCode} alt="QR code to donate to artist, Luna Smith from LuArt Gallery" title="QR code" loading="lazy" width="128px" height="128px"/>
              <button><a href="https://www.paypal.com/donate/?hosted_button_id=868S6V2VZ22HE">Donate</a></button>
          </div>
        </div>
        <PoemsSection />
        <div className="decription">
            <p>In the heart of Scotland's rugged landscapes and timeless traditions, we find a deep wellspring of inspiration – where the ancient soul of a nation meets the vibrant canvas of creativity. We invite you to embark on a unique and immersive journey, where the eloquence of Scottish poetry merges with the vivid strokes of visual art to create an extraordinary tapestry of culture and imagination.</p>
            <br />
            <p>In this enchanting exhibition, we delve into the verses of Scottish poets, who have woven their stories and emotions into the very fabric of this land. Their words, rich and resonant, have given birth to a world of dreams and visions, a world we now bring to life through the intricate brushwork of artists who, too, have felt the call of this poetic realm.</p>
            <br />
            <p>As you wander through these galleries, you'll find yourself transported through time and space. The ancient ballads of the Highlands echo alongside the bustling life of Edinburgh's cobbled streets. The haunting notes of bagpipes blend with the melodies of contemporary verse.</p>
            <br />
            <p>Here, you will witness the fusion of language and imagery, as these gifted poets and artists craft a unique synergy of sight and sound. Each painting captures the essence of a poem's themes, invoking a sense of place, time, and emotion that immerses you in the very heart of Scotland.</p>
            <br />
            <p>So, join us in this celebration of Scottish culture and creativity, where the ink of poets and the strokes of painters become one. Let your senses be awakened, your heart stirred, and your spirit carried away on the enchanting odyssey of Scottish poetry and the paintings inspired by those timeless verses.</p>
        </div>
        <div className='DonateFooter'>
          <br />
          <img src={QRCode} alt="QR code to donate to artist, Luna Smith from LuArt Gallery" title="QR code" loading="lazy" width="128px" height="128px"/>
          <button><a href="https://www.paypal.com/donate/?hosted_button_id=868S6V2VZ22HE">Donate</a></button>
        </div>
        <Footer mode={mode} />
    </div>
  )
}

export default Poems