import React, { useState } from "react";
import { nottCollection } from "../../content/artpageNott";

import { Link } from "react-router-dom";

const NottPastExhibition = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleNext = () => {
    setActiveSlide(
      (prevActiveSlide) => (prevActiveSlide + 1) % nottCollection.length
    );
  };
  const handlePrev = () => {
    setActiveSlide(
      (prevActiveSlide) =>
        (prevActiveSlide - 1 + nottCollection.length) % nottCollection.length
    );
  };
  return (
    <div>
      {nottCollection.map((painting, index) => {
        if (index === 0) {
          return (
            <div
              key={index}
              className="pastGalleryContainer"
              style={{ display: activeSlide === index ? "block" : "none" }}
            >
              <div className="imageContainerNott">
                <div>
                  <button onClick={handlePrev} className="prevButton">
                    <span className="material-symbols-outlined">
                      arrow_back_ios
                    </span>
                  </button>
                  <button onClick={handleNext} className="nextButton">
                    <span className="material-symbols-outlined">
                      arrow_forward_ios
                    </span>
                  </button>
                </div>
                <img src={painting.src} alt={painting.alt} />
              </div>
              <div className="descriptionContainer">
                <h3>{painting.title}</h3>
                <Link to={painting.link}>
                  <button>{painting.button}</button>
                </Link>
                <p>{painting.description1}</p>
                <p>{painting.description2}</p>
                <p>{painting.description3}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="pastGalleryContainer"
              style={{ display: activeSlide === index ? "block" : "none" }}
            >
              <div className="imageContainer">
                <div>
                  <button onClick={handlePrev} className="prevButton">
                    <span className="material-symbols-outlined">
                      arrow_back_ios
                    </span>
                  </button>
                  <button onClick={handleNext} className="nextButton">
                    <span className="material-symbols-outlined">
                      arrow_forward_ios
                    </span>
                  </button>
                </div>
                <img src={painting.src} alt={painting.alt} />
              </div>
              <div className="descriptionContainer">
                <h3>{painting.title}</h3>
                <h5>Approx. dimensions: {painting.dimension}</h5>
                <h4>{painting.price}</h4>
                <Link to={painting.link}>
                  <button>{painting.button}</button>
                </Link>
                <p>{painting.description1}</p>
                <p>{painting.description2}</p>
                <p>{painting.description3}</p>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default NottPastExhibition;
