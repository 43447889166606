import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

interface ModeProps {
  mode: string,
};

const JoinSupportSection = ({mode}: ModeProps) => {
  const form = useRef<any>();
  const [submit, setSubmit] = useState(false);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_wnq7xgs",
        "template_qff2epp",
        form.current,
        "cdMexHjomTqiCa1rY"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmit(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className={`JoinSupportSection ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
      <h3>
        Join <span>LuArt Gallery</span>
      </h3>
      <h4>
        Be the first to hear about our latest news, exhibitions and newest
        artworks.
      </h4>
      {!submit ? (
        <form ref={form} onSubmit={sendEmail}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="user_name"
            placeholder="enter your name..."
            required
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="user_email"
            placeholder="enter your email..."
            required
          />
          <input type="submit" value="Send" className="subscriptionButton" />
        </form>
      ) : (
        <p className="response">
          <span>Thank you. We will keep you updated.</span>
        </p>
      )}
    </div>
  );
};

export default JoinSupportSection;
