import React from "react";
import { useParams } from "react-router-dom";
import Image from "../components/Art/Image";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import { animalsCollection, hatchingEggsCollection } from "../content/artpageAnimals";
import { landscapesCollection } from "../content/artpageLandscape";
import { shadowsCollection } from "../content/artpageShadows";
import { soundOfLifeCollection } from "../content/artpageSoundOfLife";
import { nottCollectionBuy } from "../content/artpageNott";
import { stillLifeCollection } from "../content/artpageStillLife";
import { abstractsCollection } from "../content/artpageAbstracts";

const ImagePage = ({mode, handleMode}) => {
  const { title } = useParams();

  const allCollections = [
   landscapesCollection,
   animalsCollection,
   hatchingEggsCollection,
   shadowsCollection,
   soundOfLifeCollection,
   nottCollectionBuy,
   stillLifeCollection,
   abstractsCollection
  ];

  const artworks = allCollections.reduce((result, collection) => {
    return [...result, ...Object.values(collection)];
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="A stunning contemporary artwork" />
        <meta name="keywords" content="art, contemporary, Scottish art, Scottish artist, oil painting, painting, fine art, buy art, invest in art, home decor, artist, Luna Smith, LuArt Gallery" />
        <link rel="canonical" href={`https://luartgallery.com/art/${title.replace(/\s+/g, '')}.jpg`}/>
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={`https://luartgallery.com/art/${title.replace(/\s+/g, '')}.jpg`} />
        <meta property="og:url" content="https://www.luartgallery.com" />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@LuArtGallery" />
        <meta name="twitter:title" content={`${title}`} />
        <meta name="twitter:description" content={`https://luartgallery.com/art/${title.replace(/\s+/g, '')} - a stunning contemporary artwork created  by Luna Smith `} />
        <meta name="twitter:image" content={`https://luartgallery.com/art/${title.replace(/\s+/g, '')}.jpg`}/> 
      </Helmet>
      <ScrollToTop />
      <Image artworks={artworks} mode={mode} handleMode={handleMode} />
    </>
  );
};

export default ImagePage;