import React from 'react';
import ArtistSection from './ArtistSection';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ScrollToTop from '../ScrollToTop';

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Artist = ({mode, handleMode, setCategory}: ModeProps) => {
  return (
    <>
      <ScrollToTop />
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory}/>
      <ArtistSection mode={mode} />
      <Footer mode={mode}/>
    </>
  )
}

export default Artist