import React from 'react';
import { stillLifeCollection } from '../../content/artpageStillLife';
import ArtGallery from './ArtGallery';

interface PaintingProps {
    src: string;
    srcBg: string;
    alt: string;
    about: string;
    title: string;
    description: string;
    button: string;
    price: string;
    link: string;
    linkPayPal: string;
    dimension: string;
    keyWords: string;
  }

const StillLife = () => {
  return (
    <div className='artCollectionContainer'>
    <h2>StillLife</h2>
    <p>Still life is a celebration of the everyday, a masterful portrayal of the beauty found in ordinary objects. The careful arrangement of fruits, flowers, and household items creates a symphony of form, color, and texture that invites us to pause and appreciate the simple pleasures of life. The canvas becomes a stage where the mundane transforms into the extraordinary, capturing moments of quiet elegance and timelessness. Each brushstroke, each nuanced detail, tells a story of stillness and contemplation, revealing the hidden charm and significance of the things we often overlook. Still life paintings remind us to cherish the present, to find beauty in simplicity, and to reflect on the transient nature of existence. They celebrate the artistry in the everyday, encouraging us to see the world around us with fresh eyes and a renewed sense of wonder.</p>
    {stillLifeCollection.map((stilllifePainting: PaintingProps) => {
      return (
        <div key={stilllifePainting.title}>
             <ArtGallery 
                about={stilllifePainting.about} 
                alt={stilllifePainting.alt} 
                description={stilllifePainting.description}
                price={stilllifePainting.price}
                title={stilllifePainting.title}
                src={stilllifePainting.src}
                srcBg={stilllifePainting.srcBg}
                button={stilllifePainting.button}
                link={stilllifePainting.link}
                linkPayPal={stilllifePainting.linkPayPal}
                dimension={stilllifePainting.dimension}
                keyWords={stilllifePainting.keyWords}
         />
        </div>
      )
    })}
    </div>
  )
}

export default StillLife