import React from "react";
import Reviews from "../components/Footer/Reviews";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const reviews = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>reviews</title>
        <meta name="description" content="Thank you for your reviews. We are always striving to create a welcoming and engaging space for art lovers and enthusiasts, and your feedback means the world to us." />
        <meta name="keywords" content="art, art gallery, Scottish art, Edinburgh, Scotland, message, reviews, LuArt Gallery" />
        <link rel="canonical" href="https://www.luartgallery.com/reviews" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/reviews" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <Reviews mode={mode} handleMode={handleMode} />
    </>
  );
};

export default reviews;