import Nott1 from "../media/artpage/Nott/The-Warrior-of-Shattered-Tears-by-Luna-Smith.jpg";
import Nott2 from "../media/artpage/Nott/Awakening-from-the-Sleep-of-Fear-by-Luna-Smith.jpg";
import Nott3 from "../media/artpage/Nott/The-Battle-of-Endless-Seas-by-Luna-Smith.jpg";
import Nott4 from "../media/artpage/Nott/Dreams-of-a-Future-Self-by-Luna-Smith.jpg";
import Nott5 from "../media/artpage/Nott/The-Soothing-Wind-of-Longing-by-Luna-Smith.jpg";
import Nott6 from "../media/artpage/Nott/The-Heartbeat-of-the-Raging-Cyclone-by-Luna-Smith.jpg";
import Nott7 from "../media/artpage/Nott/The-Blood-of-the-Silent-Unicorn-by-Luna-Smith.jpg";
import Nott8 from "../media/artpage/Nott/The-Flame-of-the-Eternal-Triangle-by-Luna-Smith.jpg";
import Nott9 from "../media/artpage/Nott/Breathe-of-the-Invisible-Beast-by-Luna-Smith.jpg";
import Nott10 from "../media/artpage/Nott/The-Scale-of-the-Blue-Dragon-by-Luna-Smith.jpg";
import Nott11 from "../media/artpage/Nott/Kiss-of-Mothers-Love-by-Luna-Smith.jpg";
import Nott12 from "../media/artpage/Nott/Songs-of-the-Burning-Star-by-Luna-Smith.jpg";
import Nott13 from "../media/artpage/Nott/The-Fading-Reality-by-Luna-Smith.jpg";
import Nott14 from "../media/artpage/Nott/The-Reflection-of-my-Universe-by-Luna-Smith.jpg";
import Nott15 from "../media/artpage/Nott/The-Temple-of-Life-by-Luna-Smith.jpg";
import Nott1Bg from "../media/artpage/Nott/Warrior.jpg";
import Nott2Bg from "../media/artpage/Nott/Awaking.jpg";
import Nott3Bg from "../media/artpage/Nott/Battle.jpg";
import Nott4Bg from "../media/artpage/Nott/Dreams.jpg";
import Nott5Bg from "../media/artpage/Nott/SoothingWind.jpg";
import Nott6Bg from "../media/artpage/Nott/Heart.jpg";
import Nott7Bg from "../media/artpage/Nott/UnicornBlood.jpg";
import Nott8Bg from "../media/artpage/Nott/Flame.jpg";
import Nott9Bg from "../media/artpage/Nott/Breathe.jpg";
import Nott10Bg from "../media/artpage/Nott/DragonScale.jpg";
import Nott11Bg from "../media/artpage/Nott/MotherKiss.jpg";
import Nott12Bg from "../media/artpage/Nott/BurningStar.jpg";
import Nott13Bg from "../media/artpage/Nott/FadingReality.jpg";
import Nott14Bg from "../media/artpage/Nott/Universe.jpg";
import Nott15Bg from "../media/artpage/Nott/LifeTemple.jpg";
import NottPoster from "../media/artpage/Nott/Nott-current-exhibition.jpg";

export const nottCollection = [
  {
    src: NottPoster,    
    alt: "Poster of Nótt art collection",
    about: "Current Exhibition",
    title: "Nótt",
    description1:
      "Nótt is an art collection created by Scottish painter, Luna Smith, that takes the viewer on a mesmerizing journey through the endless loop of wonders. This collection of fifteen oil paintings explores the connection between the past, present, and future through symbolic imagery of the personalization of the Night from Norse mythology, and through the lens of Spirits, Goddesses, and Stones. The limited symphony of colors, with hues reminiscent of the night sky, creates a sense of depth and mystery in our Universe. Each painting is a window into a different world, where various shapes and souls come to life, inviting the viewer to discover their hidden meanings.",
    description2:
      "The Spirits are depicted in a way that makes them seem almost ethereal as if they're fleeting apparitions of the night. The Goddesses’ shapes exude grace and power, capturing their divine essence. The Stones are rendered with the power of the brushstrokes and finger-work of the painter, each one imbued with its own unique energy. The background of each painting is a brick wall, creating a sense of timelessness as if the viewer is peeking into a secret realm that exists outside of time and space.",
    description3:
      "Luna Smith has masterfully woven hidden stars throughout the paintings, adding a sense of magic and wonder. Her use of color and symbolism is nothing short of breathtaking, creating an art collection that will leave the viewer in awe and wonder. The Nott collection is a must-see for anyone interested in Norse mythology, art, time, and the beauty of the night sky.",
    button: "Aritst",
    price: "",
    link: "/artist",
    linkPayPal: "",
    dimension: "",
  },
  {
    src: Nott1,    
    alt: "The Warrior of Shattered Tears by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Warrior of Shattered Tears",
    description: "",
    button: "Card",
    price: "3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=MH98LWE6VJ7PN",
    linkPayPal: "",
    dimension: "60 X 81 X 1.5 cm",
  },
  {
    src: Nott2,    
    alt: "Awakening from the Sleep of Fear by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "Awakening from the Sleep of Fear",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=CB9R897YGTVGU",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott3,    
    alt: "The Battle of Endless Seas by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Battle of Endless Seas",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2DSTQGBZ37H3S",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott4,    
    alt: "Dreams of a Future Self by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "Dreams of a Future Self",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=9XEYH4STMWSJY",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott5,    
    alt: "The Soothing Wind of Longing by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Soothing Wind of Longing ",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JXS6VUQYQ22Z6",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott6,    
    alt: "The Heartbeat of the Raging Cyclone by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Heartbeat of the Raging Cyclone",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=S7XXWXLKL7QHA",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott7,    
    alt: "The Blood of the Silent Unicorn by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Blood of the Silent Unicorn",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=F2HZQJ8HPSHZY",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott8,    
    alt: "The Flame of the Eternal Triangle by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Flame of the Eternal Triangle",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2FW79686ABY24",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott9,
    alt: "Breath of the Invisible Beast by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "Breath of the Invisible Beast",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=VS7RHSMZYFHUW",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott10,
    alt: "The Scale of the Blue Dragon by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Scale of the Blue Dragon",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=W9N45CVY6BHXJ",
    linkPayPal: "",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott11,
    alt: "Kiss of Mother's Love by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "Kiss of Mother's Love",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RPKQPZ55CTLX8",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Nott12,
    alt: "Songs of the Burning Star by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "Songs of the Burning Star",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RARYCYPHZ58VC",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Nott13,
    alt: "The Fading Reality by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Fading Reality",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=S5EA45JXAMQMC",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Nott14,
    alt: "The Reflection of My Universe by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Reflection of my Universe",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=R6VUSTXACFFYC",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Nott15,
    alt: "The Temple of Life by Luna Smith - oil painting",
    about: "Current Exhibition",
    title: "The Temple of Life",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=QVCQMT92WS2GC",
    linkPayPal: "",
    dimension: "30 X 40 X 1.5 cm",
  },
];

export const nottCollectionBuy = [
  {
    src: Nott1,   
    srcBg: Nott1Bg,
    alt: "The Warrior of Shattered Tears by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "The Warrior of Shattered Tears",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/aEUcO56Ov3R9eZyg0N",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=MH98LWE6VJ7PN",
    dimension: "60 X 81 X 1.5 cm",
  },
  {
    src: Nott2,    
    srcBg: Nott2Bg,
    alt: "Awakening from the Sleep of Fear by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "Awakening from the Sleep of Fear",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/00g9BT3Cj3R94kU3e0",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=CB9R897YGTVGU",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott3,    
    srcBg: Nott3Bg,
    alt: "The Battle of Endless Seas by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "The Battle of Endless Seas",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/cN28xPa0HgDV6t26qb",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2DSTQGBZ37H3S",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott4,    
    srcBg: Nott4Bg,
    alt: "Dreams of a Future Self by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "Dreams of a Future Self",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/dR69BTc8P9bt8BacOy",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=9XEYH4STMWSJY",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott5,    
    srcBg: Nott5Bg,
    alt: "The Soothing Wind of Longing by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "The Soothing Wind of Longing",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/aEU8xPdcTdrJ18IbKt",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JXS6VUQYQ22Z6",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott6,    
    srcBg: Nott6Bg,
    alt: "The Heartbeat of the Raging Cyclone by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall, gem, heart, earth",
    title: "The Heartbeat of the Raging Cyclone",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/14kaFX8WDbjB7x67uc",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=S7XXWXLKL7QHA",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott7,    
    srcBg: Nott7Bg,
    alt: "The Blood of the Silent Unicorn by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall, gem",
    title: "The Blood of the Silent Unicorn",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/eVa5lD3CjdrJ6t229R",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=F2HZQJ8HPSHZY",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott8,    
    srcBg: Nott8Bg,
    alt: "The Flame of the Eternal Triangle by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall, gem",
    title: "The Flame of the Eternal Triangle",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/eVa9BTc8PfzR5oY01I",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2FW79686ABY24",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott9,    
    srcBg: Nott9Bg,
    alt: "Breath of the Invisible Beast by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall, gem",
    title: "Breath of the Invisible Beast",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/bIY4hzgp53R93gQ3dT",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=VS7RHSMZYFHUW",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott10,
    srcBg: Nott10Bg,
    alt: "The Scale of the Blue Dragon by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall, gem",
    title: "The Scale of the Blue Dragon",
    description: "",
    button: "Card",
    price: "£3500",
    link: "https://buy.stripe.com/8wM4hzgp50EX8Ba29O",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=W9N45CVY6BHXJ",
    dimension: "60 X 84 X 1.5 cm",
  },
  {
    src: Nott11,
    srcBg: Nott11Bg,
    alt: "Kiss of Mother's Love by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall, mum, mother, baby, love, family, mother's day",
    title: "Kiss of Mother's Love",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/fZecO5egX3R9bNmaFD",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RPKQPZ55CTLX8",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Nott12,
    srcBg: Nott12Bg,
    alt: "Songs of the Burning Star by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "Songs of the Burning Star",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/7sIeWd6OvbjB5oY9BA",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=RARYCYPHZ58VC",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Nott13,
    srcBg: Nott13Bg,
    alt: "The Fading Reality by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "The Fading Reality",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/cN201j1ubafxcRq5ll",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=S5EA45JXAMQMC",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Nott14,
    srcBg: Nott14Bg,
    alt: "The Reflection of My Universe by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "The Reflection of my Universe",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/14k15negX0EX18IeVW",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=R6VUSTXACFFYC",
    dimension: "30 X 40 X 1.5 cm",
  },
  {
    src: Nott15,
    srcBg: Nott15Bg,
    alt: "The Temple of Life by Luna Smith - oil painting",
    about: "Current Exhibition",
    keyWords: "universe, god, goddess, celtic, Norse, Norse Mythology, abstract, symbol, symbolic, Nott, Nòtt, night, wall",
    title: "The Temple of Life",
    description: "",
    button: "Card",
    price: "£650",
    link: "https://buy.stripe.com/eVacO5a0H5Zh9FedRT",
    linkPayPal: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=QVCQMT92WS2GC",
    dimension: "30 X 40 X 1.5 cm",
  },
];

