
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Main from '../components/Home/Main';
import TopBanner from '../components/Home/TopBanner';
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const Home = ({mode, handleMode, setCategory}) => {

  return (
    <>
      <ScrollToTop />
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory} />
      <Helmet>
        <title>LuArt Gallery</title>
        <meta name="description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <link rel="canonical" href="https://www.luartgallery.com" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <TopBanner mode={mode} />
      <Main mode={mode} />
      <Footer mode={mode}/>
    </>
  );
};

export default Home;