import React from 'react';
import Footer from './Footer';
import Header from '../Header/Header';

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Subscribe = ({mode, handleMode, setCategory}: ModeProps) => {
  return (
    <div className="Subscribe">
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory} />
      <h2>Thank you</h2>
      <h3>Have a wonderful day!</h3>
      <Footer mode={mode}/>
    </div>
  )
}

export default Subscribe