import React from "react";
import Contact from "../components/Contact/Contact";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const contact = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>contact</title>
        <meta name="description" content="Contact us to learn more about our upcoming exhibitions, events, and artist talks. Reach out today and start your journey with LuArt Gallery." />
        <meta name="keywords" content="art, art gallery, contact, LuArt Gallery, art, fine art, Luna Smith, form, question, message" />
        <link rel="canonical" href="https://www.luartgallery.com/contact" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/contact" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <Contact mode={mode} handleMode={handleMode} />
    </>
  );
};

export default contact;