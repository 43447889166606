export const poemsCollection = [
    {
        title: "The Dual Dance",
        author: "Humanlist",
        verses: [
            "Tolerance is a wee brave heart,",
            "A fool’s wisdom, a keen-edged dart.",
            "It’s laughing through a tear-stained eye,",
            "A thorn that helps the rose grow high.",
            "",
            "Tolerance is cruel kindness,",
            "A jest that heals and hurts.",
            "It’s peace among the warring sharks,",
            "Where the meek rise from the dirt.",
            "",
            "Tolerance is the comedy of strife,",
            "The dance of love and pain.",
            "A fire that sometimes fuels the fight,",
            "Yet soothes the heart again."
        ]
    },
    {
        title: "Echoes of Freedom",
        author: "Humanlist",
        verses: [
            "Tomorrow, and tomorrow, and tomorrow,",
            "Creeps in this petty pace from day to day,",
            "In Scotland's glens where echoes stray,",
            "A land of legends, where heroes lay.",
            "",
            "From misty mountains to the raging sea,",
            "Scotland's spirit soars wild and free.",
            "In every glen and Highland brae,",
            "The ancient whispers hold their sway.",
            "",
            "As leaders stumble, and freedoms decay,",
            "The spirit of Scotland holds firm today.",
            "Against oppression's chilling grasp,",
            "Together, we find strength to clasp.",
            "",
            "Let freedom's flame burn bright and strong,",
            "In the hearts of Scots, where we belong.",
            "For though oppressors may seek to bind,",
            "Our spirits soar, our wills combined."
        ]
    },
    {
        title: "Mornin' Joy",
        author: "Humanlist",
        verses: [
            "A mornin' dose o' happiness",
            "As sunlight casts its golden kiss.",
            "In ma haun, a mug o' bliss,",
            "Waukin' senses, pure an' brisk.",
            "",
            "A fragrant bloom fills the air,",
            "Dispelling darkness, banishing care.",
            "The first sip, a taste so rare,",
            "Melody of flavors, beyond compare.",
            "",
            "Worries fade, as the day unfolds,",
            "In this cherished ritual, ma heart holds.",
            "A refuge found, in a trustit cup,",
            "A sanctuary sought, in each warm sup."
        ]
    },
    {
        title: "Rubik Cube",
        author: "Humanlist",
        verses: [
            "In a realm of wonders intertwining,",
            "A vibrant mosaic, chaos refining.",
            "Colors swirl in silent chatter,",
            "A tapestry of dreams, in structured scatter.",
            "",
            "Each face is a canvas, tales yet untold,",
            "Twists and turns, a story to unfold.",
            "Chaos and order, a dance profound,",
            "Mysteries, and connections abound.",
            "",
            "Paths to explore, challenges rise,",
            "With patience and wit, we seek the prize.",
            "The beauty of this world takes flight,",
            "In tangled layers, shining bright."
        ]
    },
    {
        title: "Freya's Grace",
        author: "Humanlist",
        verses: [
            "In halls of gold, where warriors rest,",
            "Lies Freya fair, in armor dressed.",
            "With eyes of amber, hair like flame,",
            "Norse mountain calling her name.",
            "",
            "In her hand, the falcon flies,",
            "Guiding souls to Valhalla's skies.",
            "A warrior's bride, with heart so bold,",
            "In battle fierce, her tales are told.",
            "",
            "Yet in her grace, a softer side,",
            "A lover's touch, where secrets hide.",
            "With tears of gold and heart of fire",
            "She weaves the threads of love's desire."
        ]
    }, {
        title: "Ode to the Highland Beast",
        author: "Humanlist",
        verses: [
            "Amidst the heather, in Scotland's glen,",
            "Where ancient echoes dance again,",
            "The graceful deer roam wild and free,",
            "In nature's realm, where they decree.",
            "",
            "With antlers raised, majestic and grand,",
            "Crowned with pride, across the land.",
            "Their fur, a tapestry of hues,",
            "Blending seamlessly with morning dews.",
            "",
            "Through misty moors and verdant fields,",
            "Their noble presence forever yields.",
            "In every glen and mountain pass,",
            "Their presence lingers, like whispered grass.",
            "",
            "Brave and bold, they roam the glades,",
            "Through sunlit meadows and shaded shades.",
            "In the face of danger, they stand tall,",
            "Guardians of the Highland's call."
        ]
    },
    {
        title: "The Woman’s Tale",
        author: "Humanlist",
        verses: [
            "I feel like a leaf, a wild wee thing,",
            "Tossed by winds unseen, laughing in the spin.",
            "Destiny’s whispers, a cacophony of fate,",
            "Lifting me high, then dropping me late.",
            "",
            "Like a leaf in the wind, a contradiction's grace,",
            "To roam the world, seeking my own wee place.",
            "Through trials and triumphs, I flutter and steer,",
            "Nurturing courage, dismissing old fear.",
            "",
            "For I am not mere compost, no, not I,",
            "But a wise fool, resilient and sly.",
            "In death as in life, let my worth shine bright,",
            "The power of womanhood, a glorious fight."
        ]
    },
    {
        title: "Tartan Whispers",
        author: "Humanlist",
        verses: [
            "In tartan's weave, a story spins,",
            "Kilts unfurl like ancient hymns.",
            "Beneath the sky's Highland hills,",
            "Braid dance showcase of warrior's skills.",
            "",
            "Beards cascade like rugged cliffs,",
            "In defiance, each sip of whisky lifts.",
            "Pipes whisper the secrets of heroes,",
            "In melodies wisdom's echo flows."
        ]
    },
    {
        title: "The Ginger-Bearded Viking",
        author: "Humanlist",
        verses: [
            "With a beard of fiery red, long and grand,",
            "Sailed a Viking to a far-off land.",
            "He braved the cold, the endless sea,",
            "For glory, gold, and mead with glee.",
            "",
            "Beneath the waves, where shadows loom,",
            "Sea monsters stirred, craving his doom.",
            "With sword in hand, he met their roar,",
            "His beard ablaze, a flame in war.",
            "",
            "He slashed through scales, one by one,",
            "Till dawn broke with the rising sun.",
            "The legend of the flame-beard spread wide,",
            "The Viking who faced Krakens, and never died."
        ]
    },
    {
        title: "The Guard",
        author: "Humanlist",
        verses: [
            "In ancient times, upon the moor,",
            "Where whispers of old battles soar,",
            "A flower fierce and wild took root,",
            "With prickly stem and purple soot.",
            "",
            "A tale the Scots hold close and dear,",
            "Of warriors bold and hearts sincere,",
            "How this humble, hardy bloom,",
            "Saved Scotland from impending doom.",
            "",
            "The thistle, with its barbs so keen,",
            "A silent sentinel, sharp and mean,",
            "Warned clansmen of the coming fight,",
            "And drove invaders into the night."
        ]
    }, 
    {
        title: "Runes",
        author: "Humanlist",
        verses: [
            "In the heart of Yggdrasil, where roots entwine,",
            "Odin, the seeker of wisdom, made his shrine.",
            "Nine days, nine nights, he hung, pierced, and alone,",
            "Sacrifice for knowledge, to make it his own.",
            "",
            "His spear, his guide, through shadow and light,",
            "Piercing flesh, unlocking wisdom's might.",
            "In the dance of pain, a symphony of insight,",
            "Runes emerged, glowing in the night.",
            "",
            "From the depths of sacrifice, magic arose,",
            "Symbols of power, where mystery flows.",
            "Heimdall's gift to mortals, a divine bestow,",
            "Whispers of ancient truths, they silently show.",
            "",
            "In Odin's ordeal, a paradox revealed,",
            "For wisdom, he suffered, his fate sealed.",
            "A journey of agony, enlightenment concealed,",
            "In the depths of despair, secrets are revealed."
        ]
    },
    {
        title: "Highland Coo",
        author: "Humanlist",
        verses: [
            "On windswept moors, where heather blooms,",
            "A symbol of strength, the Highland Coos.",
            "With a shaggy coat, and mighty horns,",
            "Through rugged glens, they freely roam.",
            "",
            "With hooves that tread the hallowed ground,",
            "Their presence echoes with a primal sound.",
            "Majestic beasts, with hearts so true,",
            "In every glance, beauty is the power of Coo.",
            "",
            "Through winter's chill and summer's sun,",
            "Their spirits endure, and their journey spun.",
            "In tales of old and songs of lore,",
            "They'll forever dwell, forevermore."
        ]
    },
    {
        title: "Complex Void",
        author: "Humanlist",
        verses: [
            "Time says nothing,",
            "yet my love remains resolute.",
            "Time says nothing,",
            "as I grow in strength and fortitude.",
            "",
            "Time says nothing,",
            "while I craft and execute.",
            "Time says nothing,",
            "as I conceal tears and confide.",
            "",
            "Time says nothing,",
            "for I fear no death, I love, I strive.",
            "Time says nothing,",
            "in eternal wanderlust, I hate and I thrive."
        ]
    },
    {
        title: "Claidheamh Mòr",
        author: "Humanlist",
        verses: [
            "Amidst the mist, where echoes play,",
            "The Claidheamh Mòr two hands sway.",
            "A lightning bolt in warrior's prowess grip,",
            "Through time's relentless, swirling whip.",
            "",
            "Its edge, a river deep and swift,",
            "Changing currents is the greatest gift.",
            "Like thunder's roar, its battle cry,",
            "Through realms of earth and sky."
        ]
    },
    {
        title: "Thunder",
        author: "Humanlist",
        verses: [
            "In Asgard's halls, where legends bloom,",
            "There dwells a god, dispelling gloom.",
            "Thor, the Thunder, with Mjölnir's might,",
            "He brings the hope and ends the blight.",
            "",
            "His eyes, like lightning, pierce the night,",
            "Upon his chariot, goats stride high,",
            "With every clash and thunderous roar,",
            "He guards the realms forevermore.",
            "",
            "A friend to mortals, strong and true,",
            "In every heart, his legend grew.",
            "In feast and fight, in peace and war,",
            "We raise our voices and sing of Thor."
        ]
    },
    {
        title: "Metamorphosis",
        author: "Humanlist",
        verses: [
            "We are the waves upon the sea,",
            "Endless motion, eternally free.",
            "In the dance of time, we rise and fall,",
            "Yet never vanish, merely evolve.",
            "",
            "We are the flame within the fire,",
            "A spark that leaps, never to tire.",
            "When embers fade and ashes lie,",
            "Our essence soars, embracing the sky.",
            "",
            "We are the leaves on autumn trees,",
            "In colors bright, a final tease.",
            "When winter comes and leaves must go,",
            "We become the earth, where new life will grow.",
            "",
            "We are the stars in the midnight sky,",
            "Burning bright, then winking goodbye.",
            "As one light fades, another is born,",
            "In cosmic cycles, forever adorned."
        ]
    },
    {
        title: "The Northern Dance",
        author: "Humanlist",
        verses: [
            "Whispers say that on a frigid night,",
            "Freyja’s dreams and wisdom take flight,",
            "Emerald greens and sapphire blues,",
            "In rhythmic pulses, they twist and fuse.",
            "",
            "In the realm where the north winds sigh,",
            "The Northern Lights paint the sky,",
            "Whispering tales, a holy beam,",
            "Unseen pearls of gods, a spectral gleam.",
            "",
            "Thoughts, like fairies, begin to soar,",
            "Casting hues on the heavens' floor,",
            "A symphony of colors, bold and free,",
            "A celestial waltz for all to see.",
            "",
            "So when you gaze at the sky so wide,",
            "And see the lights in their majestic glide,",
            "Remember Freyja, fair, loving, and bright,",
            "Whose dreams become the Northern Light."
        ]
    },
    {
        title: "Life Architect",
        author: "Humanlist",
        verses: [
            "In the heart's arid land,",
            "Let the healing waters flow,",
            "In the womb of sacrifice,",
            "Let life’s seeds in Eden grow.",
            "",
            "Amid the storm of despair,",
            "A mother’s love, a guiding flare,",
            "Through the chrysalis of change,",
            "Her affirming gaze, unwavering, remains.",
            "",
            "Woman, architect of being,",
            "Crafting forms with unseen seeing,",
            "With hands that shape and hearts that dream,",
            "She weaves the endless, flowing stream."
        ]
    },
    {
        title: "The Victorious Valkyrie",
        author: "Humanlist",
        verses: [
            "In the land of ice, a battle raged,",
            "Valkyrie was fierce, on warpath engaged.",
            "She faced a Frost Giant, tall and grim,",
            "With strength and courage, she challenged him.",
            "",
            "Though weaker in might, she was sharp and keen,",
            "Her wits outmatched his brutal sheen.",
            "She dodged and weaved, her strikes precise,",
            "Her bravery became a warrior's guide."
        ]
    },
    {
        title: "Happiness",
        author: "Humanlist",
        verses: [
            "Happiness is free—take it,",
            "Ecstasy floats in the air after rain,",
            "Cheer blooms in a stranger's smile,",
            "Bliss whispers in the wind’s refrain.",
            "",
            "Contentment fills a satisfied heart,",
            "Joyfulness dances to our inner song,",
            "It all resides within our minds,",
            "Where happiness, boundless, belongs.",
            "",
            "Embrace it, for sorrow's cost is steep,",
            "Happiness is free—take it,",
            "In its embrace, let your spirit leap.",
            "Isolation's price is way too high."
        ]
    },
    {
        title: "Dreams of Sorrow",
        author: "Humanlist",
        verses: [
            "About suffering, they were never wrong,",
            "The Old Goddesses: how well they understood.",
            "",
            "For the miracles of birth, there is blood and pain;",
            "For the miracles of love, there are losses and hate.",
            "",
            "For the miracles of evolution, the weak must perish;",
            "For the miracles of comfort, nature's grave is cherished.",
            "",
            "For the miracles of the web, isolation binds;",
            "For the miracles of light, contamination blinds.",
            "",
            "The ocean of life is never just black and white—",
            "It flows in soothing blue, day and night."
        ]
    },
    {
        title: "The Swan",
        author: "Humanlist",
        verses: [
            "In the twilight of the ancient Norse,",
            "Where legends breathe and myths endorse,",
            "A swan emerged from the waters deep,",
            "Guarding secrets the gods would keep.",
            "",
            "Freyja, goddess of love and light,",
            "Sent this swan in a cloak of white,",
            "To dance upon the mirrored lake,",
            "Where dreams are born, and sorrows break.",
            "",
            "With feathers soft as winter's breath,",
            "She soared above the realms of death,",
            "A symbol pure, of life, renewed,",
            "In her wings, all strength imbued.",
            "",
            "A warrior lost, with the heart of stone,",
            "By swan's enchantment was atoned,",
            "In her reflection, he saw the past,",
            "A love returned, and a spell was cast."
        ]
    }    
];
