import React, { useRef } from 'react';
import { useBasket } from './BasketContext';
import Header from './Header/Header';
import ScrollToTop from './ScrollToTop';
import emailjs from "@emailjs/browser";

interface BasketItem {
  src: string;
  alt: string;
  title: string;
  dimension: string;
  price: string;
}

interface ModeProps {
  mode: string;
  handleMode: () => void;
  setCategory: (category: string) => void;
}

const Basket = ({ mode, handleMode, setCategory }: ModeProps) => {
  const { basket, removeFromBasket } = useBasket();
  const formRef = useRef<HTMLFormElement>(null);

  const sendEmail = (item: BasketItem) => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const userMessage = formData.get('user_message')?.toString() || '';
      const userEmail = formData.get('user_email')?.toString() || '';

      const templateParams = {
        user_name: `Title: ${item.title}, Price: ${item.price}`,
        user_message: userMessage,
        user_email: userEmail
      };

      emailjs.send('service_wnq7xgs', 'template_jybanxb', templateParams, 'cdMexHjomTqiCa1rY')
        .then((result) => {
          console.log('Email sent successfully:', result.text);
        }, (error) => {
          console.error('Error sending email:', error.text);
        });
    }
  };

  const handleSubmit = () => {
    basket.forEach(item => sendEmail(item));
  };

  const totalPrice = basket.reduce((total, item) => total + parseFloat(item.price.replace('£', '')), 0);

  return (
    <div className='BasketPage'>
      <ScrollToTop />
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory} />
      <div className={`Basket ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
        <h1><span>Your basket - make your dreams a reality.</span></h1>
        {basket.length === 0 ? (
          <p>Your basket is empty</p>
        ) : (
          <>
            {basket.map((item, index) => (
              <div key={index} className="basketItem">
                <h3><span>{item.title}</span></h3>
                <div className='imgContainer'>
                  <img src={item.src} alt={item.alt} />
                </div>
                <p>{item.price}</p>
                <button onClick={() => removeFromBasket(item.title)}>Remove</button>
              </div>
            ))}
            <div className="totalPrice">
              <h3><span>Total Price:</span> £{totalPrice.toFixed(2)}</h3>
              <form
                ref={formRef}
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                onSubmit={handleSubmit}
              >
                <label htmlFor="message">Email</label>
                <p>Please provide your email address so we can send you updates regarding your package.</p>
                <input
                  name="user_email"
                  placeholder="your email..."
                  required
                />
                <label htmlFor="message">Message</label>
                <p>Please include the shipping address for your order.</p>
                <textarea
                  name="user_message"
                  placeholder="your address..."
                  required
                />
                <input type="hidden" name="cmd" value="_xclick" />
                <input type="hidden" name="business" value="artistlunasmith@gmail.com" />
                <input type="hidden" name="item_name" value="Basket Total" />
                <input type="hidden" name="currency_code" value="GBP" />
                <input type="hidden" name="amount" value={totalPrice.toFixed(2)} />
                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_paynow_SM.gif" name="submit" alt="Pay with PayPal" className='buttonTotal' />
              </form>
              <p>*FREE DELIVERY</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Basket;