import React from 'react';
import { portraitsCollection } from '../../content/artpagePortrait';
import ArtGallery from './ArtGallery';

interface PaintingProps {
    src: string;
    srcBg: string;
    alt: string;
    about: string;
    title: string;
    description: string;
    button: string;
    price: string;
    link: string;
    linkPayPal: string;
    dimension: string;
    keyWords: string;
  }

const Portraits = () => {
  return (
    <div className='artCollectionContainer'>
    <h2>Portraits</h2>
    <p>Portraits are a gateway to the human soul, a captivating glimpse into the essence of individual personalities and emotions. The subtle expressions, the depth in the eyes, and the delicate play of light and shadow all converge to create a harmonious narrative of human experience. The canvas serves as a timeless vessel, capturing and immortalizing these intimate moments, allowing us to cherish them forever. Each brushstroke, each blend of color, reveals the unique story of the subject, reflecting their inner world and the myriad emotions they embody. Portraits remind us to pause and appreciate the profound beauty of human connection and the stories each person carries within. They celebrate the rich tapestry of humanity, inviting us to recognize and honor the individuality and shared experiences that bind us together.</p>
    {portraitsCollection.map((portraitPainting: PaintingProps) => {
      return (
        <div key={portraitPainting.title}>
             <ArtGallery 
                about={portraitPainting.about} 
                alt={portraitPainting.alt} 
                description={portraitPainting.description}
                price={portraitPainting.price}
                title={portraitPainting.title}
                src={portraitPainting.src}
                srcBg={portraitPainting.srcBg}
                button={portraitPainting.button}
                link={portraitPainting.link}
                linkPayPal={portraitPainting.linkPayPal}
                dimension={portraitPainting.dimension}
                keyWords={portraitPainting.keyWords}
         />
        </div>
      )
    })}
    </div>
  )
}

export default Portraits