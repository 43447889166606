import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import Subscribe from "../components/Footer/Subscribe";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const subscribe = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>subscribe</title>
        <meta name="description" content="Stay up to date with the latest news and events from LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Join our community of art enthusiasts and discover the transformative power of art." />
        <meta name="keywords" content="art, art gallery, gallery, exhibition, contemporary, Scottish art, newsletter, subscription, subscribe, LuArt Gallery" />
        <link rel="canonical" href="https://www.luartgallery.com/subsribe" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/subsribe" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <Subscribe mode={mode} handleMode={handleMode} />
    </>
  );
};

export default subscribe;