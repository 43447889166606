import React, { useState } from "react";
import { shadowsCollection } from "../../content/artpageShadows";

import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import NottPastExhibition from "./NottPastExhibition";

interface ModeProps {
  mode: string,
};

const PastExhibition = ({mode}:ModeProps) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleNext = () => {
    setActiveSlide(
      (prevActiveSlide) => (prevActiveSlide + 1) % shadowsCollection.length
    );
  };
  const handlePrev = () => {
    setActiveSlide(
      (prevActiveSlide) =>
        (prevActiveSlide - 1 + shadowsCollection.length) %
        shadowsCollection.length
    );
  };

  return (
    <div className={`PastExhibition ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
      <ScrollToTop />
      <h2><span>Exhibition</span></h2>
      <NottPastExhibition />
      {shadowsCollection.map((shadowPainting, index) => {
        if (index === 0) {
          return (
            <div
              key={index}
              className="pastGalleryContainer"
              style={{ display: activeSlide === index ? "block" : "none" }}
            >
              <div className="imageContainer">
                <div>
                  <button onClick={handlePrev} className="prevButton">
                    <span className="material-symbols-outlined">
                      arrow_back_ios
                    </span>
                  </button>
                  <button onClick={handleNext} className="nextButton">
                    <span className="material-symbols-outlined">
                      arrow_forward_ios
                    </span>
                  </button>
                </div>
                <img src={shadowPainting.src} alt={shadowPainting.alt} />
              </div>
              <div className="descriptionContainer">
                <h3>{shadowPainting.title}</h3>
                <Link to={shadowPainting.link}>
                  <button>{shadowPainting.button}</button>
                </Link>
                <p>{shadowPainting.description}</p>
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={index}
              className="pastGalleryContainer"
              style={{ display: activeSlide === index ? "block" : "none" }}
            >
              <div className="imageContainer">
                <div>
                  <button onClick={handlePrev} className="prevButton">
                    <span className="material-symbols-outlined">
                      arrow_back_ios
                    </span>
                  </button>
                  <button onClick={handleNext} className="nextButton">
                    <span className="material-symbols-outlined">
                      arrow_forward_ios
                    </span>
                  </button>
                </div>
                <img src={shadowPainting.src} alt={shadowPainting.alt} />
              </div>
              <div className="descriptionContainer">
                <h3>{shadowPainting.title}</h3>
                <h5>Approx. dimensions: {shadowPainting.dimension}</h5>
                <h4>{shadowPainting.price}</h4>
                <Link to={shadowPainting.link}>
                  <button>{shadowPainting.button}</button>
                </Link>
                <p>{shadowPainting.description}</p>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default PastExhibition;
