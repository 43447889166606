import { useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import CanvasAnimation from './CanvasAnimation';
import Semicircle from './Semicircle';
import Odin from "../../media/artpage/Landscapes/OdinsReverie.jpg";
import Owl from "../../media/artpage/Animals/Owl-by-Luna-Smith.jpg";
import Guardian from "../../media/artpage/Landscapes/Guardian-Tree-by-Luna-Smith.jpg";
import Spooky from "../../media/artpage/Animals/SpookyKitty_ByLunaSmith.jpg";

const variantsHeader = {
  initial: { x: -300 },
  animate: { x: 0, transition: { duration: 0.5 } },
};

const variantsSubHeader = {
  initial: { x: 300 },
  animate: { x: 0, transition: { duration: 0.5 } },
};

interface ModeProps {
  mode: string,
};

const TopBanner = ({mode}: ModeProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      new CanvasAnimation(canvasRef.current);
    }

  }, []);

  return (
    <div className={`TopBanner ${mode === 'light' ? 'lightMode' : 'darkMode'}`}>
        <canvas ref={canvasRef}></canvas>
        <motion.div className='titleContainer '       
          initial='initial'
          whileInView='animate'>
          <h1>
            <motion.div
            variants={variantsHeader}>
              LuArt Gallery 
            </motion.div>
            <motion.div
              variants={variantsSubHeader}>
              <span>a new world of contemporary art</span>
            </motion.div>
          </h1>
          </motion.div>
        <motion.div
          initial={{opacity: 1}}
          animate={{
            opacity: [1, 0.5, 0, 0.5, 1],
          }}
          transition={{
            duration: 8,
            ease: "easeInOut",
            times: [0, 0.3, 0.5, 0.7, 1],
            repeat: Infinity,
            repeatDelay: 1
          }}
        >
        <Semicircle position='left' imageSrc={Owl} title="Owl by Luna Smith" />
      </motion.div>
      <motion.div
      initial={{opacity: 0}}
      animate={{
        opacity: [0, 0.5, 1, 0.5, 0],
      }}
      transition={{
        duration: 8,
        ease: "easeInOut",
        times: [0, 0.3, 0.5, 0.7, 1],
        repeat: Infinity,
        repeatDelay: 1
      }}
      >
        <Semicircle position='left' imageSrc={Odin} title="Odin's Reverie by Luna Smith"/>
      </motion.div>

      <motion.div
      initial={{opacity: 1}}
      animate={{
        opacity: [1, 0.5, 0, 0.5, 1],
      }}
      transition={{
        duration: 8,
        ease: "easeInOut",
        times: [0, 0.3, 0.5, 0.7, 1],
        repeat: Infinity,
        repeatDelay: 1
      }}
      >
        <Semicircle position='right' imageSrc={Guardian} title="Guardian Tree by Luna Smith"/>
      </motion.div>
      <motion.div
       initial={{opacity: 0}}
       animate={{
         opacity: [0, 0.5, 1, 0.5, 0],
       }}
       transition={{
         duration: 8,
         ease: "easeInOut",
         times: [0, 0.3, 0.5, 0.7, 1],
         repeat: Infinity,
         repeatDelay: 1
       }}
      >
        <Semicircle position='right' imageSrc={Spooky} title="Spooky Kitty by Luna Smith"/>
      </motion.div>
    </div>
  )
}

export default TopBanner