import React from "react";
import { animalsCollection, hatchingEggsCollection } from "../../content/artpageAnimals";
import ArtGallery from "./ArtGallery";

interface PaintingProps {
  src: string;
  srcBg: string;
  alt: string;
  about: string;
  title: string;
  description: string;
  button: string;
  price: string;
  link: string;
  linkPayPal: string;
  dimension: string;
  keyWords: string;
}

const Animals = () => {
  return (
    <>
      <h2>Animals</h2>
      <p>
        Luna Smith, a Scottish artist, has created a stunning collection of oil
        paintings that capture the essence and beauty of various animals. Her
        use of intense colors and distinctive brushstrokes lend a sense of
        energy and vitality to each of her works. <br />
        Luna's technique involves layering rich, vibrant colors to create a
        sense of depth and texture. The animals in her paintings seem to be in
        motion, their coats rippling and flowing as if they were caught in a
        gust of wind. <br />
        Perhaps most impressive is Luna's ability to capture the personality and
        spirit of each animal she paints. From the fierce determination of an
        owl to the playful curiosity of shrimp, Luna's paintings bring out the
        unique character of every creature.
      </p>
      {animalsCollection.map((animalPainting: PaintingProps, index: number) => {
        return (
          <div key={index}>
            <ArtGallery
              about={animalPainting.about}
              alt={animalPainting.alt}
              description={animalPainting.description}
              price={animalPainting.price}
              title={animalPainting.title}
              src={animalPainting.src}
              srcBg={animalPainting.srcBg}
              button={animalPainting.button}
              link={animalPainting.link}
              linkPayPal={animalPainting.linkPayPal}
              dimension={animalPainting.dimension}
              keyWords={animalPainting.keyWords}
            />
          </div>
        );
      })}
      <h2>Hatching Eggs</h2>
      <p>
      Step into the Hatching Egg collection and witness the birth of new hope. These artworks are a celebration of the miracle of life, as they depict the moment when egg cracks open and a new being emerges into the world. The colors used in these pieces range from soft pastels to bold hues, creating a visual spectrum that is both soothing and invigorating. The use of light and shadow gives the impression of the eggs being on the verge of hatching as if at any moment, a new life will be born. Each piece is a reminder of the beauty and fragility of life and the endless possibilities that come with it. Each artwork evokes feelings of wonder, awe, and most importantly, hope.
      </p>
       {hatchingEggsCollection.map((hatchingPainting: PaintingProps, index: number) => {
        return (
          <div key={index}>
            <ArtGallery
              about={hatchingPainting.about}
              alt={hatchingPainting.alt}
              description={hatchingPainting.description}
              price={hatchingPainting.price}
              title={hatchingPainting.title}
              src={hatchingPainting.src}
              srcBg={hatchingPainting.srcBg}
              button={hatchingPainting.button}
              link={hatchingPainting.link}
              linkPayPal={hatchingPainting.linkPayPal}
              dimension={hatchingPainting.dimension}
              keyWords={hatchingPainting.keyWords}
            />
          </div>
        );
      })}
    </>
  );
};

export default Animals;
