import React from "react";
import Poems from "../components/WhatsOn/Poems";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const poems = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>poems</title>
        <meta name="description" content="Experience the essence of Scottish culture with the poems of Humanist, Luna Smith. Celebrate the spirit of Scotland through lyrical expressions." />
        <meta name="keywords" content="poems, Scottish poet, poets, Scotland, art, art gallery, gallery, exhibition, contemporary, Scottish art, Scottish artist, Edinburgh, fine art, painting, oil paintings, buy art, invest in art, home decor, exhibition, artist, Luna Smith, LuArt Gallery" />
        <link rel="canonical" href="https://www.luartgallery.com/poems" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/poems" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <Poems mode={mode} handleMode={handleMode} />
    </>
  );
};

export default poems;