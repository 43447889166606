import React from 'react'
import Footer from './Footer'
import Header from '../Header/Header'
import ReviewSection from './ReviewSection'
import ScrollToTop from '../ScrollToTop'

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Reviews = ({mode, handleMode, setCategory}: ModeProps) => {
  return (
    <>
    <ScrollToTop />
    <Header mode={mode} handleMode={handleMode} setCategory={setCategory}/>
    <ReviewSection mode={mode}/>
    <Footer mode={mode} />
  </>
  )
}

export default Reviews