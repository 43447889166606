import React from "react";

import { Link } from "react-router-dom";
import DonateFooter from "./DonateFooter";

interface ModeProps {
  mode: string,
};

const JobsSection = ({mode}:ModeProps) => {
  return (
    <div className="JobsSection">
      <h2>Work at LuArt Gallery</h2>
      <p>Dear Art Enthusiasts,</p>
      <p>
        We have some exciting news! New opportunities for employment at our
        gallery are coming soon. Whether you have a passion for art, a keen eye
        for detail, or a love of customer service, there is sure to be a role
        that suits you. Keep an eye on our website and social media for updates
        and be ready to join our team. We look forward to welcoming you onboard.
      </p>
      <p>Please contact us for more details.</p>
      <p>Best regards, LuArt Gallery.</p>
      <button>
        <Link to="/contact">Contact us</Link>
      </button>
      <DonateFooter mode={mode} />
    </div>
  );
};

export default JobsSection;
