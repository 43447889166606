import React from "react";
import About from "../components/About/About";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const about = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>about</title>
        <meta name="description" content="Discover LuArt Gallery, a contemporary art gallery located in Edinburgh, Scotland. Invest in fine art and add a touch of beauty and magic to your life." />
        <meta name="keywords" content="art, art gallery, gallery, exhibition, contemporary, Scottish art, Scottish artist, Edinburgh, Scotland, fine art, painting, oil paintings, buy art, invest in art, home decor, exhibition, artist, Luna Smith, LuArt Gallery" />
        <link rel="canonical" href="https://www.luartgallery.com/about" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/about" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <About mode={mode} handleMode={handleMode} />
    </>
  );
};

export default about;