import { useState, useEffect } from 'react';
import PastExhibition from './PastExhibition';
import DonateFooter from '../Footer/DonateFooter';

interface ModeProps {
  mode: string,
};

const ExhibitionSection = ({mode}:ModeProps) => {

  const [targetElementId, setTargetElementId] = useState('');

  useEffect(() => {
    const targetElement = document.getElementById(targetElementId);
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth'});      
    }
  }, [targetElementId]);

  const handleButtonClick = (id: string) => {
    setTargetElementId(id);
  };

  return (
    <div className={`ExhibitionSection ${mode === 'light' ? 'lightMode' : 'darkMode'}`} id="exhibitionTop">
      <div id="past">
      <div className='space'></div>
        <PastExhibition mode={mode} />
      </div>
      <button onClick={() => handleButtonClick('exhibitionTop')}>
          Scroll to Top
      </button>
     <DonateFooter mode={mode} />
    </div>
  )
}

export default ExhibitionSection