import React from 'react';

interface SemicircleProps {
  position: 'left' | 'right';
  imageSrc: string;
  title: string;
}

const Semicircle: React.FC<SemicircleProps> = ({ position, imageSrc, title }) => {
    const semicircleStyle = {
        width: '20vw',
        height: '100vh',
        overflow: 'hidden',
        position: 'absolute',
        top: '0',
        [position]: '0',
        clipPath: position === 'left' 
          ? 'polygon(0 0, 0 0, 100% 50%, 0 100%)' 
          : 'polygon(0 50%, 100% 0, 100% 100%, 100% 100%)',
        zIndex: 1,
    } as React.CSSProperties;


  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  } as React.CSSProperties;

  return (
    <div style={semicircleStyle}>
      <img src={imageSrc} alt="Semicircle" style={imgStyle} loading="lazy" title={title} width="auto" height="auto"/>
    </div>
  );
};

export default Semicircle;