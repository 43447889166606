import React from 'react';
import ArtSection from './ArtSection';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ScrollToTop from '../ScrollToTop';

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
  category: string;
};

const Art = ({mode, handleMode, setCategory, category}:ModeProps) => {
  return (
    <>
    <ScrollToTop />
    <Header mode={mode} handleMode={handleMode} setCategory={setCategory} />
    <ArtSection mode={mode} category={category}/>
    <Footer mode={mode}/>
  </>
  )
}

export default Art