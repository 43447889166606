import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import ReturnTerms from "../components/Footer/ReturnTerms";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const returns = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>Returns</title>
        <meta name="description" content="Learn about the returns policy of art at LuArt Gallery's website. Our Returns page outlines important information about our privacy policy, copyright, and liability limitations. By accessing or using our website or services, you agree to be bound by these Returns and conditions." />
        <meta name="keywords" content="art, art gallery, gallery, Returns, condtions, purchase, LuArt Gallery" />
        <link rel="canonical" href="https://www.luartgallery.com/returns" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/returns" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <ReturnTerms mode={mode} handleMode={handleMode} />
    </>
  );
};

export default returns;