import React from 'react'
import Footer from './Footer'
import Header from '../Header/Header'
import ScrollToTop from '../ScrollToTop'
import TermsSection from './TermsSection'

interface ModeProps {
  mode: string,
  handleMode: () => void,
  setCategory: (category: string) => void;
};

const Terms = ({mode, handleMode, setCategory}: ModeProps) => {
  return (
    <>
      <ScrollToTop />
      <Header mode={mode} handleMode={handleMode} setCategory={setCategory}/>
      <TermsSection />
      <Footer mode={mode} />
  </>
  )
}

export default Terms