import React from 'react';
import { useParams, Link } from "react-router-dom";
import { motion } from 'framer-motion';

interface ArtworkItem {
  src: string;
  srcBg: string;
  alt: string;
  about: string;
  title: string;
  description: string;
  button: string;
  price: string;
  link: string;
  linkPayPal: string;
  dimension: string;
  keyWords: string;
}

interface PaintingProps {
  artworks: ArtworkItem[];
}


const Image: React.FC<PaintingProps> = ({ artworks }) => {
  const { title: artworkTitle } = useParams();

  //Trim the artworkTitle once and store it in a variable
  const trimmedArtworkTitle = artworkTitle?.replace(/\s+/g, '') || '';

  // Filter the artworks that include the trimmedArtworkTitle as part of their title
  const filteredArtworks = artworks.find( art =>
    art.title.replace(/\s+/g, '').toLowerCase() ===
    trimmedArtworkTitle?.toLowerCase());

  if (!filteredArtworks) {
    // Handle the case when no matching artworks are found
    return <div>No artworks found for the given title.</div>;
  }

  return (
    <div className='Image'>
      <Link to={`https://luartgallery.com/art/${filteredArtworks.title.replace(/\s+/g, '')}`}>
        <div className='close'>X</div>
      </Link>
      <motion.div
      initial={{ scale: 1}}
      whileHover={{ scale: 1.3 }}>
        <img src={filteredArtworks.src} alt={filteredArtworks.alt} className='mainImage'/>
      </motion.div>
      <div className='empty'></div>
    </div>
  );
}

export default Image;