import React from 'react';
import { landscapesCollection } from '../../content/artpageLandscape';
import ArtGallery from './ArtGallery';

interface PaintingProps {
    src: string;
    srcBg: string;
    alt: string;
    about: string;
    title: string;
    description: string;
    button: string;
    price: string;
    link: string;
    linkPayPal: string;
    dimension: string;
    keyWords: string;
  }

const Landscapes = () => {
  return (
    <div className='artCollectionContainer'>
    <h2>Landscapes</h2>
    <p>Landscapes are a window into the soul of nature, a breathtaking display of the earth's raw beauty and power. The rolling hills, majestic mountains, and sparkling waters all come together to create a symphony of color and form. The canvas is a medium that allows us to capture and preserve this beauty, to hold memories forever in our hearts and minds. Each brush stroke, each splash of the paint, tells a story of the wonder and majesty of nature, and the infinite possibilities it holds. They remind us to take a moment, to stop and appreciate the magic that surrounds us, and to be grateful for being part of it.</p>
    {landscapesCollection.map((landscapePainting: PaintingProps) => {
      return (
        <div key={landscapePainting.title}>
             <ArtGallery 
                about={landscapePainting.about} 
                alt={landscapePainting.alt} 
                description={landscapePainting.description}
                price={landscapePainting.price}
                title={landscapePainting.title}
                src={landscapePainting.src}
                srcBg={landscapePainting.srcBg}
                button={landscapePainting.button}
                link={landscapePainting.link}
                linkPayPal={landscapePainting.linkPayPal}
                dimension={landscapePainting.dimension}
                keyWords={landscapePainting.keyWords}
         />
        </div>
      )
    })}
    </div>
  )
}

export default Landscapes