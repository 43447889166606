import React from "react";
import Terms from "../components/Footer/Terms";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import Facebook from "../media/artpage/Nott/Warrio-facebookr.jpg";

const terms = ({mode, handleMode}) => {
  return (
    <>
      <Helmet>
        <title>terms</title>
        <meta name="description" content="Learn about the terms and conditions of using LuArt Gallery's website and services. Our terms page outlines important information about our privacy policy, copyright, and liability limitations. By accessing or using our website or services, you agree to be bound by these terms and conditions." />
        <meta name="keywords" content="art, art gallery, gallery, terms, condtions, purchase, LuArt Gallery" />
        <link rel="canonical" href="https://www.luartgallery.com/terms" />
        <meta property="og:title" content="LuArt Gallery" />
        <meta property="og:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest and experience the transformative power of art." />
        <meta property="og:image" content={Facebook} />
        <meta property="og:url" content="https://www.luartgallery.com/terms" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@LuArtGallery" /> 
        <meta name="twitter:title" content="LuArt Gallery" />
        <meta name="twitter:description" content="Discover LuArt Gallery, a contemporary art gallery in Edinburgh, Scotland. Invest in fine art and experience the transformative power of art." />
        <meta name="twitter:image" content={Facebook} /> 
      </Helmet>
      <ScrollToTop />
      <Terms mode={mode} handleMode={handleMode} />
    </>
  );
};

export default terms;