import CurrentExhibition from '../media/upcomingExhibition/ExhibitionIconNow.jpg';
import PastExhibition from '../media/upcomingExhibition/ExhibitionIconPast.jpg';
import UpcomingExhibition from '../media/upcomingExhibition/ExhibitionIconFuture.jpg';
import MeetArtist from '../media/homepage/Luna Smith with Nott collection.jpg';
import ShopArt from '../media/homepage/LuArt Gallery ad1.1.jpg';
import ContactLogo from '../media/upcomingExhibition/ContactLogo.jpg';

export const homepage = [
    {
        id: 1,
        src: ShopArt,
        alt: "LuArt Gallery, a new world of contemporary art",
        about: "Invest in Art",
        title: "LuArt Gallery",
        description: "LuArt Gallery is a contemporary art gallery located in the heart of Edinburgh, Scotland. We are dedicated to showcasing the best in contemporary Scottish art, featuring a diverse range of subjects and mediums.",
        button: "View",
        link: "/art",
    },
    {
        id: 2,
        src: MeetArtist,
        alt: "Luna Smith, with the Nott collection, in her art studio in Edinburgh, Scotland",
        about: "Meet the artist",
        title: "Luna Smith",
        description: "Luna Smith is a Scottish artist from Edinburgh. She specializes in visually striking, symbolic, and emotionally resonant pieces. Her signature style is characterized by the use of her fingers, and palette knives to creates textured, layered paintings. Luna's work is instantly recognizable and highly sought after by collectors and art enthusiasts.",
        button: "View",
        link: "/artist",
    },
    {
        id: 3,
        src: CurrentExhibition,
        alt: "Poems",
        about: "Scottish Poetry",
        title: "Poems",
        description: "In the heart of Scotland's rugged landscapes and timeless traditions, we find a deep wellspring of inspiration – where the ancient soul of a nation meets the vibrant canvas of creativity. We invite you to embark on a unique and immersive journey, where the eloquence of Scottish poetry merges with the vivid strokes of visual art to create an extraordinary tapestry of culture and imagination.",
        button: "View",
        link: "/poems",
    },
    {
        id: 4,
        src: PastExhibition,
        alt: "Nótt by Luna Smith",
        about: "Exhibition",
        title: "Nótt",
        description: "Nótt is an art collection created by Scottish painter, Luna Smith, that takes the viewer on a mesmerizing journey through the endless loop of wonders. This collection of fifteen oil paintings explores the connection between the past, present, and future through symbolic imagery of the personalization of the Night from Norse mythology, and through the lens of Spirits, Goddesses, and Stones.",
        button: "View",
        link: "/exhibitions",
    },
    {
        id: 5,
        src: UpcomingExhibition,
        alt: "Chasing Light - a Scottish Impression",
        about: "Upcoming Exhibition",
        title: "Chasing Light",
        description: "Step into a world where the ethereal beauty of Scotland's landscapes comes to life through the masterful brushwork of Luna Smith. 'Chasing Light - A Scottish Impression' is an exquisite exhibition that invites you to immerse yourself in the breathtaking scenery of the Scottish highlands, coastlines, and urban vistas, all captured in Luna's unique impressionistic style.",
        button: "View",
        link: "/upcomingexhibitions",
    },
    {
        id: 6,
        src: ContactLogo,
        alt: "Contact Us",
        about: "Contact Us",
        title: "Get in Touch",
        description: "Scottish contemporary fine art is a vibrant and diverse genre that encompasses a wide range of styles and subject matter. From stunning landscapes to captivating abstracts, from expressive animal portraits to powerful figurative pieces, the Luna Smith's oil painting offers something for every taste.",
        button: "View",
        link: "/contact",
    },

]